import { Footer } from "../components/Footer";
import Testimonial from "../shared/utils/images/Testimonial.png";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import React from "react";
import { VolunteerForm } from "../components/VolunteerForm";

export default function Volunteer() {
  return (
    <>
      <div className="overflow-hidden bg-gray-50 pt-40 pb-16">
        <div className="mx-auto max-w-7xl space-y-8 px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-prose text-base lg:max-w-none">
            <h2 className="text-lg font-semibold text-pink">Vino la noi</h2>
            <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Voluntariat
            </p>
          </div>
          <div className="relative z-10 mx-auto max-w-prose text-base leading-7 lg:mx-0 lg:max-w-5xl lg:pr-72">
            <p className="text-gray-500">
              Când ți-e drag de copii și drag de a face bine, nu poți sta
              departe 😊. Așa sunt voluntarii noștri inimoși care petrec
              săptămânal câteva ore cu copii din zone și familii defavorizate
              pentru a-i ajuta să țină pasul cu programa școlară și să pună
              bazele educației lor atât formale cât și, de multe ori, chiar
              civice.
            </p>
          </div>
          <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-8">
            <div className="relative z-10">
              <div className="prose text-base leading-7 mx-auto text-gray-500 lg:max-w-none text-justify">
                <p>
                  Lecții la mate, la limba română sau la științe, povești despre
                  viață, glume și situații comice de la școală, momente mai
                  puțin amuzante când se iau note mici sau când “doamna” sau
                  colegii sunt lipsiți de empatie – toate astea și multe altele
                  fac parte din viața unui voluntar.
                </p>
                <p>
                  Dacă timpul și energia investite s-ar cuantifica în zâmbete și
                  satisfacții, clar ieșim pe plus atunci când suntem alături de
                  copii, online sau fizic, pregătiți să punem bazele la educația
                  unui copil sau când datorită nouă acel copil petrece mai puțin
                  timp la muncile casei și mai mult la formarea sa ca om.
                </p>
                <h3>Implică-te</h3>
                <p>
                  Te poți implica să sprijini activitățile de acces la educație
                  și punctual când este nevoie de a seta o conexiune la net sau
                  de a instala un progrămel util în procesul de învățare al
                  copiilor sau dacă vrei să iți exersezi talentul și abilitățile
                  de content writer pe paginile noastre de social media.
                </p>
                <p>
                  Ideea este că la noi găsești un loc în care să contribui și în
                  același timp să faci ceva fain și util pentru tine, doar să
                  știm ce iți dorești 😊.
                </p>
                <p>
                  Lasă-ne datele tale de contact și ne auzim la telefon să
                  povestim despre când și cu cine poți începe lecțiile. Îți
                  oferim ghidare, training și toate resursele (laptop & stuff)
                  de care copiii au nevoie astfel încât experiența să fie cât mai
                  plăcută și mai facilă pentru amândoi. Avem pregătite pentru
                  tine workshopuri de dezvoltare personală și întâlniri cu
                  oameni faini, menite să te inspire și să aducă valoare în
                  viața ta.
                </p>
              </div>
            </div>
            <div className="relative mx-auto mt-12 max-w-prose text-base lg:mt-0 lg:max-w-none">
              <svg
                className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"
                />
              </svg>
              <blockquote className="relative rounded-lg bg-white shadow-lg">
                <div className="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8">
                  <img src={Testimonial} alt="Testimonial" className="h-8" />
                  <div className="relative mt-8 text-base font-medium text-gray-700">
                    <svg
                      className="absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-gray-200"
                      fill="currentColor"
                      viewBox="0 0 32 32"
                      aria-hidden="true"
                    >
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                    </svg>
                    <p className="relative text-justify">
                      Bună! Consider că a venit momentul să vă împărtășesc puțin
                      si eu din magia copilașului cu care lucrez. Nu o să îi
                      spun numele, dar acest lucru nu cred că va influența deloc
                      ceea ce urmează să vă spun.
                      <br /> <br />
                      M-am întâlnit cu el de câteva ori și de fiecare dată a
                      fost voios și dornic să colaborăm. Am aflat o mulțime de
                      lucruri despre el și el despre mine în cadrul primei
                      noastre întâlniri. Noi lucrăm mai mult la comunicare în
                      limba română și ne petrecem orele cu jocuri, citim,
                      povestim, râdem, ne uităm la filmulețe și scriem. Nu
                      rezolvăm teme pentru că momentan nu a fost cazul (este
                      harnic și le rezolvă foarte rapid).
                      <br /> <br />
                      Mi-a povestit despre prietenii lui cei mai buni, despre
                      școală, despre ceea ce face el in timpul lui liber, mi-a
                      spus care este culoarea lui preferată, mi-a aratat cana
                      din care își bea ceaiul dimineață și nu numai. Deși este
                      un pic timid, începe încet să se deschidă, fapt ce mă
                      bucură enorm. Abia aștept să ne vedem și mâine!
                    </p>
                  </div>
                </div>
                <cite className="relative flex items-center rounded-b-lg bg-blueDDB-500 py-5 px-6 not-italic sm:items-start sm:py-5 sm:pl-12 sm:pr-10">
                  <span className="relative font-semibold leading-6 text-blueDDB-100 ">
                    <span className="font-semibold text-white sm:inline">
                      Ioana,
                    </span>{" "}
                    <span className="sm:inline">super-voluntar</span>
                  </span>
                </cite>
              </blockquote>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-hidden bg-white py-16 px-4 sm:px-6 lg:px-8 lg:py-24">
        <div className="relative mx-auto max-w-xl">
          <svg
            className="absolute left-full translate-x-1/2 transform"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={404}
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <svg
            className="absolute right-full bottom-0 -translate-x-1/2 transform"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={404}
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <div className="text-center">
            <h2
              id="contact"
              className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
            >
              Contactează-ne
            </h2>
            <p className="mt-4 text-lg leading-6 text-gray-500">
              Completează datele tale în acest formular dacă dorești
              să devii voluntar, iar noi te vom contacta.
            </p>
          </div>
          <div className="mt-12">
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
            >
              <VolunteerForm />
            </GoogleReCaptchaProvider>
          </div>
        </div>
      </div>

      <section className="Footer">
        <Footer />
      </section>
    </>
  );
}
