import ReactGA4 from "react-ga4";

const useGoogleCookiesTracker = (
  category = "Category",
  action = "action",
  label = "label"
) => {
  const trackEvent = () => {
    ReactGA4.event({
      category,
      action,
      label,
    });
  };

  return trackEvent;
};

export default useGoogleCookiesTracker;