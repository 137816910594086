export default function PrivacyPolicy() {
    return (
      <div className="relative overflow-hidden bg-white pt-40 pb-16">        
        <div className="relative px-4 sm:px-6 lg:px-8">
         <div className="mx-auto max-w-7xl space-y-8 px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-prose text-base lg:max-w-none">
            <h2 className="text-lg font-semibold text-pink">Regulament</h2>
            <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Privacy Policy
            </p>
          </div>
          </div>
          <div className="prose prose-lg max-w-7xl py-16 px-4 sm:px-6 lg:px-8 prose-indigo mx-auto mt-6 text-gray-500">
            <p>
              ASOCIAȚIA DRAG DE BINE, se aliniaza reglementarolor privind protectia datelor cu caracter personal conform 
              Regulamentului UE nr.679/2016. Astfel, prin aceasta Politica de confidentialitate, 
              organizatia noastra isi asigura toate partile interesate de respectarea dreptului la 
              protectia datelor cu caracter personal, precum si dreptul la viata privata. <br />
              Confidentialitatea vizitatorilor website-ului nostru este foarte importanta pentru noi si ne luam angajamentul sa o protejam. 
              Aceasta politica explica ce vom face cu informatiile dumneavoastra personale.
            </p>


            <h2>1. Informatii generale</h2>
            <h3>1.1. Operatorul de date</h3>
            <p>
              Operatorul care proceseaza datele cu caracter personal este: <br />
              <span className="font-bold">ASOCIAȚIA DRAG DE BINE</span>  <br />
              Sibiu, Str. General Dragalina, nr. 1, judetul Sibiu <br />
              C.I.F. 46515374 <br />
              eliza@dragdebine.ro <br />
              Site <span href="#" className="underline text-pink">www.dragdebine.ro</span> <br />
            </p>
            <h3>1.2. Responsabilul cu protectia datelor (DPO)</h3>
            <p>
              Puteti contacta persoana responsabila cu protectia datelor: <br />
              <span className="font-bold">ASOCIAȚIA DRAG DE BINE</span>  <br />
              Sibiu, Str. General Dragalina, nr. 1, judetul Sibiu <br />
              Eliza Ceprăzaru <br />
              Tel. 0739 925 643 <br />
              Email: dpo@dragdebine.ro              
            </p>


            <h2>2. Definitii</h2>
            <p>
              <span className="italic underline">Date cu Caracter Personal</span> - inseamna orice informatii privind o persoana fizica 
                identificata sau identificabila ("persoana vizata"); o persoana fizica identificabila este o persoana care
                poate fi identificata, direct sau indirect, in special prin referire la un element de identificare, cum ar fi un
                nume, un numar de identificare, date de localizare, un identificator online, sau la unul sau mai multe elemente
                specifice, proprii identitatii sale fizice, fiziologice, genetice, psihice, economice, culturale sau sociale.             
            </p>
            <p>
              <span className="italic underline">Prelucrare</span> - inseamna orice operatiune sau set de operatiuni efectuate asupra 
                datelor cu caracter personal sau asupra seturilor de date cu caracter personal, cu sau fara utilizarea de mijloace
                automatizate, cum ar fi colectarea, inregistrarea, organizarea, structurarea, stocarea, adaptarea sau modificarea,
                extragerea, consultarea, utilizarea, divulgarea prin transmitere, diseminarea sau punerea la dispozitie in orice
                alt mod, alinierea sau combinarea, restrictionarea, stergerea sau distrugerea.             
            </p>
            <p>
              <span className="italic underline">Operator</span> - inseamna persoana fizica sau juridica, autoritatea publica,
                agentia sau alt organism care, singur sau impreuna cu altele, stabileste scopurile si mijloacele de prelucrare a 
                datelor cu caracter personal; atunci cand scopurile si mijloacele prelucrarii sunt stabilite prin dreptul Uniunii
                sau dreptul intern, operatorul sau criteriile specifice pentru desemnarea acestuia pot fi prevazute in dreptul 
                Uniunii sau in dreptul intern.            
            </p>
            <p>
              <span className="italic underline">Persoana Imputernicita de Operator</span> - inseamna persoana fizica sau juridica,
                 autoritatea publica, agentia sau alt organism care prelucreaza datele cu caracter personal in numele operatorului.          
            </p>
            <p>
              <span className="italic underline">Utilizator</span> - inseamna orice persoana care actioneaza sub autoritatea 
                operatorului, a persoanei imputernicite sau a reprezentantului, cu drept recunoscut de acces la bazele de date cu caracter 
                personal.          
            </p>
            <p>
              <span className="italic underline">Destinatar </span> - inseamna persoana fizica sau juridica, autoritatea publica, agentia 
                sau alt organism careia (caruia) ii sunt divulgate datele cu caracter personal, indiferent daca este sau nu o parte terta.
                Cu toate acestea, autoritatile publice carora li se pot comunica date cu caracter personal in cadrul unei anumite anchete
                in conformitate cu dreptul Uniunii sau cu dreptul intern nu sunt considerate destinatari; prelucrarea acestor date de 
                catre autoritatile publice respective respecta normele aplicabile in materie de protectie a datelor, in conformitate cu
                scopurile prelucrarii.         
            </p>
            <p>
              <span className="italic underline">Parte Terta </span> - inseamna o persoana fizica sau juridica, autoritate publica, agentie
                sau organism altul decat persoana vizata, operatorul, persoana imputernicita de operator si persoanele care, sub directa 
                autoritate a operatorului sau a persoanei imputernicite de operator, sunt autorizate sa prelucreze date cu caracter personal.      
            </p>
            <p>
              <span className="italic underline">Consimtamant </span> - al persoanei vizate inseamna orice manifestare de vointa libera, 
                specifica, informata si lipsita de ambiguitate a persoanei vizate prin care aceasta accepta, printr-o declaratie sau
                printr-o actiune fara echivoc, ca datele cu caracter personal care o privesc sa fie prelucrate.      
            </p>
            <p>
              <span className="italic underline">Incalcarea  Securitatii Datelor cu Caracter Personal</span> - inseamna o incalcare a 
                securitatii care duce, in mod accidental sau ilegal, la distrugerea, pierderea, modificarea, sau divulgarea neautorizata 
                a datelor cu caracter personal transmise, stocate sau prelucrate intr-un alt mod, sau la accesul neautorizat la acestea.     
            </p>


            <h2>3. Ce inseamna prelucrarea datelor cu caracter personal si ce date sunt prelucrate de ASOCIAȚIA DRAG DE BINE?</h2>
            <p>
              Informatiile pe care le colectam de la dumneavoatra si modul in care facem colectarea variaza in functie de serviciile
              ASOCIAȚIA DRAG DE BINE pe care le utilizati, modul in care interactionati sau ati interactionat cu
              ASOCIAȚIA DRAG DE BINE sau in functie de datele obtinute de la o terta parte care avea acordul de a face schimb 
              de informatii cu noi.     
            </p>
            <p>
              ASOCIAȚIA DRAG DE BINE colecteaza urmatoarele date: <br />
               <ul>
                <li>date de identificare: numele, prenumele, adresa de corespondenta electronica, numar de telefon;</li>
                <li>disponibilitatea clientului intr-o anumita perioada - daca ;</li>
                <li>alte informatii care deriva in urma prelucrarilor efectuate de ASOCIAȚIA DRAG DE BINE, cum ar 
                    fi: identificatorul unic generat la nivel de ASOCIAȚIA DRAG DE BINE pentru fiecare client in parte,
                    istoricul contractual, istoricul financiar, informatii specifice despre serviciile contractate de la 
                    ASOCIAȚIA DRAG DE BINE si despre felul in care clientul le-a utilizat;
                </li>
                <li>corespondenta electronica cu clientul;</li>
                <li>cookies (Google Analytics cookies folosite pentru identificarea user-ilor).</li>
              </ul>
            </p>
            <p>
              ASOCIAȚIA DRAG DE BINE va asigura de faptul ca datele cu caracter personal vor fi: <br />
               <ul>
                <li>prelucrate cu buna credinta si in conformitate cu dispozitiile legale;</li>
                <li>colectate in scopuri determinate, explicite si legitime;</li>
                <li>adecvate, pertinente si neexcesive, prin raportare la scopul in care sunt colectate si ulterior prelucrate;</li>
                <li>exacte si actualizate - datele inexacte sau incomplete din punct de vedere al scopului pentru care sunt colectate 
                    si respectiv prelucrate vor fi sterse sau rectificate;
                </li>
                <li>stocate intr-o forma care sa permita identificarea persoanei vizate strict pe perioada; necesara realizarii
                    scopurilor in care datele sunt colectate si„Prelucrare” inseamna orice operatiune sau set de operatiuni 
                    efectuate asupra datelor cu caracter personal sau asupra seturilor de date cu caracter personal, cu sau 
                    fara utilizarea de mijloace automatizate, cum ar fi colectarea, inregistrarea, organizarea, structurarea, 
                    stocarea, adaptarea sau modificarea, extragerea, consultarea, utilizarea, divulgarea prin transmitere,
                    diseminarea sau punerea la dispozitie in orice alt mod, alinierea sau combinarea, restrictionarea, stergerea
                    sau distrugerea.
                </li>
              </ul>
            </p>
            <h4>Referitor la cookie, ce sunt acestea si cum sunt utilizate?</h4>
            <p>
              Cookie-urile sunt fisiere de mici dimensiuni, alcatuite in general dintr-un sir de caractere, sau parti dintr-un 
              fisier, care la accesarea unui site sunt salvate in browser-ul utilizat de catre calculatorul, telefonul, tableta
              sau orice alt dispozitiv prin care se acceseaza on-line respectivul site. La fiecare accesare ulterioara a 
              site-ului browser-ul utilizat trimite catre server-ul respectivului site acest fisier, in scopul de a permite 
              identificarea unui vizitator care a revenit pe site.
              <br />
              In general, site-urile folosesc cookie-uri pentru a facilita oferirea catre vizitatori a unor functionalitati
              care nu pot fi asigurate in lipsa acestora de protocolul http, protocol de tip text care este cel mai des
              utilizat pentru accesarea informatiilor on-line de pe servere web (www). Aceste functionalitati constau
              in managementul sesiunilor utilizatorilor, pastrarea unei sesiuni autentificate, pastrarea preferintelor 
              pe o pagina vizitata (de exemplu, aspecte legate de functionalitatea sau de afisarea grafica a paginilor) 
              si altele. 
              <br />
              Cand o persoana acceseaza site-ul ca vizitator, un cookie este trimis catre browser-ul sau de internet si salvat pe 
              hard-disk-ul computerului sau, dar aceasta poate bloca salvarea cookie-urilor prin modificarea setarilor browser-ului.
              <br />
              Cookie-urile pot stoca informatii ce au un caracter personal (de exemplu, cod de identificare al vizitatorului, 
              preferinte personalizate sau un istoric al paginilor vizitate). Aceste informatii nu sunt generate de cookie-uri, ci
              de catre vizitator, in momentul in care acesta completeaza formularele on- line, se inregistreaza pe site, utilizeaza 
              sisteme de plati electronice etc. Desi cookie-urile sunt stocate in memoria calculatorului, telefonului, tabletei sau a 
              oricarui alt dispozitiv utilizat pentru accesarea unui site, cookie-urile nu pot accesa sau citi alte informatii stocate 
              in respectivul dispozitiv.
              <br />
              Cookie-urile nu sunt virusi, nu sunt compilate sub forma de cod si nu pot fi executate. In consecinta, acestea nu se pot
              auto-copia, nu se pot raspandi in alte retele pentru a genera anumite actiuni si nu pot fi utilizate pentru raspandirea
              de virusi.
              <br />
              In functie de durata, cookie-urile pot fi cookie-uri de sesiune sau cookie-uri permanente. Cookie-urile de sesiune au
              o durata de stocare temporara, limitata doar la durata sesiunii in care un anumit vizitator acceseaza site-ul. 
              La momentul inchiderii sesiunii sau a browser-ului, toate informatiile stocate sunt sterse.
              <br />
              Cookie-urile permanente sunt stocate in dispozitivul utilizat de catre vizitator si nu sunt sterse la momentul 
              inchiderii sesiunii sau a browser-ului.
              <br />
              Browser-ele web ofera functionalitati de setare a nivelului de securitate a informatiilor, permitand vizitatorilor 
              sa opteze ca preferintele lor sa nu fie inregistrate, astfel ca se poate bloca utilizarea oricarui cookie prin modificarea 
              setarilor browser-ului. Pentru a utiliza facilitatile de setare a nivelului de acceptare a cookie-urilor, in majoritatea 
              cazurilor se acceseaza sectiunea „Setari” / „Internet options”, sub-sectiunea „Confidentialitate si securitate” / „Privacy” 
              din meniul de browser (in functie de browser-ul utilizat).
              <br />
              Dezactivarea optiunii de acceptare a cookie-urilor poate avea ca implicatii imposibilitatea accesarii unora dintre cele mai 
              importante sectiuni ale site-ului. Din acest motiv este recomandabila acceptarea cookie-urilor apartinand site-urilor pe care 
              le considerati de incredere. In orice moment doriti aveti posibilitatea de a sterge cookie-urile stocate in dispozitivul pe 
              care il utilizati, accesand sectiunea „Setari” / „Safety”, sub-sectiunea „Confidentialitate si securitate” / „Delete browsing 
              history” din meniul de browser (in functie de browser-ul utilizat).
              <br />
              Pentru detalii complete privind modul in care noi utilizam cookie accesati Politica de cookie.
            </p>


            <h2>4. De unde sunt obtinute datele cu caracter personal pe care le prelucreaza ASOCIAȚIA DRAG DE BINE?</h2>
            <p>
              ASOCIAȚIA DRAG DE BINE prelucreaza date cu caracter personal referitoare la Client, la mandatarii Clientului, 
              legali sau conventionali, precum si la persoanele ale caror date sunt furnizate de catre Client catre ASOCIAȚIA DRAG DE BINE, 
              in vederea realizarii unei operatiuni/prestari de serviciu. Persoanele fizice ale caror date cu caracter personal 
              sunt prelucrate poarta denumirea de „Persoane vizate”. 
              <br />
              ASOCIAȚIA DRAG DE BINE se adreseaza urmatoarelor persoane vizate: colaboratori proprii, clienti (chiar si dupa incetarea
              relatiei contractuale), clientii potentiali, candidatii care sunt in cautarea unui loc de munca si apeleaza la intermedirea
              ASOCIAȚIA DRAG DE BINE, vizitatorii website-ului ASOCIAȚIA DRAG DE BINE.
              <br />
              Datele sunt obtinute direct de la Client si/sau de la un imputernicit al Clientului (in momentul in care se completeaza 
              formulare sau documente ale ASOCIAȚIA DRAG DE BINE, este accesata o pagina de internet, sau prin alte mijloace de 
              comunicare). In plus, ASOCIAȚIA DRAG DE BINE poate obtine datele de mai sus si prin consultarea unor surse externe 
              (institutii si autoritati publice, registre publice, baze de date electronice, informatii disponibile in mediul online
              sau terti abilitati).
            </p>
            <p>
              ASOCIAȚIA DRAG DE BINE colecteaza date personale atunci cand:
               <ul>
                <li>utilizati formularul de contact de pe site-ul www.dragdebine.ro ;</li>
                <li>participati la evenimente organizate de ASOCIAȚIA DRAG DE BINE sau la care suntem parteneri;</li>
                <li>transmiteti mesaje prin email, telefonic sau prin alte mijloace de comunicare;</li>
                <li>datele dumneavoatra cu caracter personal sunt publice;</li>
              </ul>
            </p>
            <p>
              ASOCIAȚIA DRAG DE BINE are conturi oficiale pe diferite platforme sociale, unde dumneavoastra puteti face 
              comentarii, asumandu-va conditiile de realizare a acestora.
              <br />
              Daca este cazul, facem schimb de informatii referitoare la dumneavoastra cu:
               <ul>
                <li>organisme de aplicare a legii;</li>
                <li>terta parte cand dezvaluirea este necesara pentru indeplinirea unei cerinte legale sau pentru 
                  impiedicarea unor fraude, apararea drepturilor si proprietatii, apararea intereselor dumneavoastra.
                </li>
              </ul>
            </p>
           

            <h2>5. Principii</h2>
            <p>
               <ul>
                <li>Buna-credinta si respectarea dispozitiilor legale in vigoare</li>
                <li>Prelucrarea datelor include colectarea, inregistrarea, organizarea, 
                      stocarea, consultarea, utilizarea, transferul, combinarea, blocarea, stergerea sau distrugerea lor.
                </li>
                <li>Datele obtinute se vor prelucra numai in scopurile permise de lege. Legea impune conditii suplimentare
                    cand este vorba de date sensibile, referitoare la originea rasiala sau etnica, convingerile politice, religioase, 
                    apartenenta sindicala, starea de sanatate sau viata sexuala. ASOCIAȚIA DRAG DE BINE nu colecteaza date 
                    considerate sensibile, coform legii.
                </li>
                <li>Prelucrarea datelor in scopuri determinate, explicite si legitime</li>
                <li>Pelucrarea ulterioara a datelor cu caracter personal in scopuri statistice sau de cercetare nu va fi 
                    considerata incompatibila cu scopul colectarii, daca se efectueaza cu respectarea dispozitiilor legale in vigoare, 
                    precum si cu respectarea garantiilor privind prelucrarea datelor cu caracter personal, prevazute de normele care 
                    reglementeaza activitatea statistica ori cercetarea.
                </li>
                <li>Prelucrarea unor date adecvate, pertinente si neexcesive prin raportare la scopul in care sunt colectate</li>
                <li>Prelucrarea unor date exacte si actualizate;</li>
                <li>In acest scop se vor lua masurile necesare pentru ca datele inexacte sau incomplete din punct de vedere al 
                    scopului pentru care sunt colectate si pentru care vor fi ulterior prelucrate, sa fie sterse sau rectificate;
                </li>
                <li>Stocarea datelor intr-o forma care sa permita identificarea persoanelor vizate strict pe durata necesara 
                    realizarii scopurilor in care datele sunt colectate si in care vor fi ulterior prelucrate;
                </li>
                <li>Stocarea datelor pe o durata mai mare decat cea mentionata, in scopuri statistice sau de cercetare se va
                    face cu respectarea garantiilor privind prelucrarea datelor cu caracter personal, prevazute in normele 
                    care reglementeaza aceste domenii, si numai pentru perioada necesara realizarii acestor scopuri.
                </li>
              </ul>
            </p>


            <h2>6. In ce scopuri prelucreaza ASOCIAȚIA DRAG DE BINE date cu caracter personal?</h2>
            <p>
              Scopurile pentru care ASOCIAȚIA DRAG DE BINE prelucreaza datele cu caracter personal sunt:
               <ul>
                <li>furnizarea de servicii prin intermediul tututor canalelor disponibile in acest sens (locatii 
                    fizice, internet, telefon, etc.);
                </li>
                <li>furnizazarea de profiluri clienti unor operatori terti in vederea identificarii uor oportunitati de munca oferite 
                    clientilor;
                </li>
                <li>efectuarea unor activitati de gestiune economica, financiara si/sau administrativa in cadrul ASOCIAȚIA DRAG DE BINE;
                </li>
                <li>centralizarea operatiunilor si mentinerea unei baza de date interne in care sa fie stocate informatiile cu privire la 
                    persoanele vizate, pentru a putea fi utilizate de catre ASOCIAȚIA DRAG DE BINE in activitatile sale;
                </li>
                <li>contactarea Clientului/altei persoane vizate prin intermediul mijloacelor de comunicare cu scopul de a-i aduce la 
                    cunostinta/notifica informatii despre serviciilele contractate (de ex. expirare termen contract, neindeplinire obligatii,
                    modificare/completare, costuri, beneficii);
                </li>
                <li>furnizarea de servicii suport pentru solicitarile Clientului/persoanei vizate (de ex. informatii aditionale despre servicii,
                    actualizarea unora din datele de identificare, solutionarea cererilor, reclamatiilor si a petitiilor fomulate), prin
                    intermediul mijloacelor de comunicare (telefon/email/posta);
                </li>
                <li>crearea sau analizarea de profiluri pentru imbunatatirea serviciilor, pentru promovarea personalizata/generala, pentru 
                    efectuarea de activitati de marketing direct prin intermediul oricaror mijloace de comunicare (telefon/email/posta/fax/sms
                    sau a unor reclame adresate in mod direct si specific unei anumite persoane) in cazul in care Clientul a acceptat aceasta;
                </li>
                <li>analizarea comportamentului Clientului/oricarei persoane care acceseaza website-ul, prin folosirea de cookies, atat ale 
                    ASOCIAȚIA DRAG DE BINE, cat si ale tertilor, cu scopul de a furniza un continut general sau particularizat, oferte adaptate
                    intereselor utilizatorilor;
                </li>
                <li>efectuarea de analize interne (incluzand analize statistice), atat cu privire la servicii, cat si cu privire la portofoliul de 
                    clienti;
                </li>
                <li>arhivarea atat in format fizic cat si electronic a documentelor;
                </li>
                <li>solutionarea litigiilor, investigatiilor sau oricaror alte petitii/plangeri/solicitari la care ASOCIAȚIA DRAG DE BINE este 
                    parte;
                </li>
                <li>pentru indeplinirea obligatiilor legale (intocmirea actelor salariale, raportarea la institutiile publice indreptatite).
                </li>
              </ul>
            </p>
            
            
            <h2>7. In ce temeiuri prelucreaza ASOCIAȚIA DRAG DE BINE date cu caracter personal?</h2>
            <p>
            ASOCIAȚIA DRAG DE BINE prelucreaza datele cu caracter personal in scopurile mentionate mai sus, in baza 
            urmatoarelor temeiuri:
               <ul>
                <li>in baza consimtamantului Clientului, acordat in mod explicit prin acceptarea prevederilor sectiunii Termeni si conditii;
                </li>
                <li>pentru executarea unui contract la care Clientul/persoana vizata este parte (furnizarea de servicii), pentru a face 
                    demersuri pre-contractuale la cererea Clientului sau pentru a furniza Clientului informatii despre  serviciile oferite de 
                    ASOCIAȚIA DRAG DE BINE;
                </li>
                <li>in baza unei obligatii legale aflate in sarcina ASOCIAȚIA DRAG DE BINE (de ex. identificarea si prevenirea fraudelor);
                </li>
                <li>in baza interesului legitim al ASOCIAȚIA DRAG DE BINE (de ex. centralizarea operatiunilor, functionarea unei baze de 
                    date interne, indeplinirea operatiunilor curente pentru desfasurarea activitatilor, dezvoltarea si imbunatatirea serviciilor, 
                    asigurarea unui nivel ridicat de securitate atat la nivelul sistemelor informatice, cat si la nivelul locatiilor fizice, in 
                    special in ceea ce priveste descoperirea si minimizarea riscurilor ce pot afecta ASOCIAȚIA DRAG DE BINE).
                </li>
              </ul>
            </p>


            <h2>8. Cum protejam datele personale?</h2>
            <p>
            ASOCIAȚIA DRAG DE BINE, in calitate de Operator, a adoptat urmatoarele solutii de organizare in ceea ce priveste confidentialitatea 
            datelor: 
               <ul>
                <li>este certificata ISO 27001;
                </li>
                <li>aspectele tehnice de securitate a datelor intra in responsabilitatea Conducerii si trebuie gestionate atat in baza liniilor 
                    directoare definite, a proceselor si procedurilor, cat si prin controale efectuate la nivelul sistemelor informatice;
                </li>
                <li>in baza unei obligatii legale aflate in sarcina ASOCIAȚIA DRAG DE BINE (de ex. identificarea si prevenirea fraudelor);
                </li>
                <li>responsabilitatea privind prelucrarea datelor in acord cu prezenta politica revine tuturor Colaboratorilor, 
                    ASOCIAȚIA DRAG DE BINE asigura masurile organizatorice necesare implementarii prevederilor Politicilor interne 
                    adoptate privind confidentialitate datelor, astfel incat prelucrarile datelor cu caracter personal sa fie efectuate 
                    in conformitate cu Regulamentul (UE) 2016/679;
                </li>
                <li>ASOCIAȚIA DRAG DE BINE a desemnat un Responsabil cu protectia datelor care supervizeaza toate activitatile de 
                    prelucrare a datelor personale si se asigura ca si subcontractantii sai si-au asumat conformitate cu Regulamentul (UE) 
                    2016/679. Desemnarea responsabilitatilor reflecta cerintele ASOCIAȚIA DRAG DE BINE mentionate mai sus;
                </li>
                <li>Toti colaboratorii ASOCIAȚIA DRAG DE BINE sunt obligati sa respecte Politica de confidentialitate precum si 
                    masurile de prelucrare a datelor cu caracter personal, asigurand-se un nivel adecvat  de protectie a datelor astfel prelucrate.
                </li>
              </ul>
            </p>


            <h2>9. Care sunt consecintele refuzului de a comunica datele personale?</h2>
            <p>
              Prelucrarea datelor cu caracter personal solicitate de catre ASOCIAȚIA DRAG DE BINE prin formulare/alte canale de comunicare 
              este obligatorie, cu exceptia cazului in care prelucrarea se intemeiaza doar pe acordul Clientului. In acest caz, Clientul 
              va fi informat ca furnizarea datelor, respectiv a acordului, este optionala. In celelalte cazuri, refuzul va determina 
              imposibilitatea furnizarii de servicii de catre ASOCIAȚIA DRAG DE BINE.
            </p>


            <h2>10. Cine sunt destinatarii datelor cu caracter personal prelucrate de ASOCIAȚIA DRAG DE BINE?</h2>
            <p> Destinatarii datelor pot fi:
               <ul>
                <li>furnizori de servicii: servicii IT (mentenanta, dezvoltare software), arhivare in format fizic/electronic, curierat, 
                    furnizori de servicii de marketing, de monitorizare a traficului si comportamentului utilizatorilor;
                </li>
                <li>furnizori de servicii de contabilitate;
                </li>
                <li>operatori terti aflati in spatiul UE;
                </li>
                <li>autoritati publice centrale si/sau locale.
                </li>
              </ul>
            </p>


            <h2>11. Cat timp sunt prelucrate si stocate datele cu caracter personal?</h2>
            <p>
              In vederea realizarii scopurilor sus-mentionate, datele cu caracter 
              personal vor fi prelucrate de catre ASOCIAȚIA DRAG DE BINE pe tot parcursul 
              relatiei contractuale si dupa finalizarea acesteia, in vederea conformarii legale 
              aplicabile in domeniu, inclusiv, dar fara limitare, la dispozitiile in materia arhivarii.
              <br />
              Informatiile colectate de cookies sunt stocate timp de 26 de luni in vederea realizarii de a
              nalize si rapoarte legate de performantele website-urilor. Ele vor fi sterse ulterior acestei perioade.
              <br />
              Informatii din fisele clientilor se pastreaza pe tot parcursul relatiei contractuale si dupa finalizarea 
              acesteia, in vederea conformarii legale aplicabile in domeniu, inclusiv, dar fara limitare, la dispozitiile in materia arhivarii.
            </p>


            <h2>12. Care sunt drepturile persoanelor vizate si cum pot fi exercitate?</h2>
            <p>Persoana vizata are urmatoarele drepturi:
               <ul>
                <li>Dreptul la informare – dreptul de a primi informatii detaliate privind activitatile de prelucrare efectuate de 
                    ASOCIAȚIA DRAG DE BINE, conform celor prevazute in prezentul document;
                </li>
                <li>Dreptul de acces – poate solicita si obtine confirmarea faptului ca datele sale cu caracter personal sunt 
                    prelucrate sau nu de catre ASOCIAȚIA DRAG DE BINE, iar in caz afirmativ poate solicita accesul la acestea, 
                    precum si anumite informatii. La cerere, ASOCIAȚIA DRAG DE BINE va elibera si o copie a datelor cu caracter 
                    personal prelucrat, copiile suplimentare putand fi tarifate in functie de costurile efective ale 
                    ASOCIAȚIA DRAG DE BINE ce vor fi communicate anterior;
                </li>
                <li>Dreptul la rectificare – dreptul de a obtine rectificarea datelor cu caracter personal inexacte si completarea 
                    celor incomplete;
                </li>
                <li>Dreptul la stergerea datelor (”dreptul de a fi uitat”) – in situatiile reglementate expres de lege (in 
                    special in cazul retragerii consimtamantului sau in cazul in care se constata ca prelucrarea datelor cu caracter 
                    personal nu a fost legala), persoana vizata poate obtine stergerea respectivelor date. Urmare a unei astfel de 
                    solicitari, ASOCIAȚIA DRAG DE BINE poate sa anonimizeze datele, lipsindu- le de caracterul personal si sa 
                    continue astfel prelucrarea in scopuri statistice;
                </li>
                <li>Dreptul la restrictionarea prelucrarii – in situatiile reglementate expres de lege (in special in cazul in 
                    care se contesta exactitatea respectivelor date pe perioada necesara pentru determinarea acestei inexactitati 
                    sau in cazul in care prelucrarea este ilegala, si nu se doreste stergerea datelor, ci doar restrictionarea);
                </li>
                <li>
                    Dreptul de opozitie – se poate opune in orice moment, din motive legate de situatia particulara in care se 
                    afla, prelucrarilor intemeiate pe interesul legitim al ASOCIAȚIA DRAG DE BINE (inclusiv crearii de 
                    profiluri) sau realizate in exercitiul unui interes public sau al unei autorizari cu care este investita 
                    ASOCIAȚIA DRAG DE BINE;
                </li>
                <li>Dreptul la portabilitatea datelor – poate primi datele cu caracter personal intr-un format structurat, 
                    care poate fi citit automat, sau poate solicita ca respectivele date sa fie transmise altui operator. Acest 
                    drept este aplicabil numai: (i) pentru datele cu caracter personal furnizate de catre Client 
                    ASOCIAȚIA DRAG DE BINE, (ii) daca prelucrarea datelor cu caracter personal este realizata prin mijloace 
                    automate si (iii) daca prelucrarea are ca temei legal fie executarea unui contract, fie consimtamantul persoanei vizate;
                </li>
                <li>Dreptul de a depune plangere – poate depune plangere fata de modalitatea de prelucrare a datelor cu caracter personal de 
                    catre ASOCIAȚIA DRAG DE BINE la Autoritatea Nationala de Supraveghere a Prelucrarii Datelor cu Caracter Personal;
                </li>
                <li>Dreptul de retragere a consimtamantului – in cazurile in care prelucrarea se intemeiaza pe consimtamant, acesta poate 
                    fi retras oricand. Retragerea consimtamantului va avea efecte doar pentru viitor, prelucrarea efectuata anterior retragerii 
                    ramanand in continuare valabila;
                </li>
                <li>Drepturi suplimentare aferente deciziilor automate utilizate in procesul de furnizare a serviciilor 
                    ASOCIAȚIA DRAG DE BINE – in cazul in care ASOCIAȚIA DRAG DE BINE ia decizii automate in legatura cu datele cu 
                    caracter personal, persoana vizata poate (i) cere si obtine interventia umana cu privire la respectiva prelucrare, 
                    (ii) isi poate exprima punctul de vedere cu privire la respectiva prelucrare si contesta decizia.
                </li>
              </ul>
            </p>
            <br />

            <p>
              Persoana vizata poate exercita aceste drepturi fie individual, fie cumulat, prin transmiterea unei cereri scrise, datate si
              semnate, la sediul ASOCIAȚIA DRAG DE BINE Sibiu, Str. General Dragalina, nr. 1, judetul Sibiu sau prin email: 
              eliza@dragdebine.ro.
            </p>
            <br />

            <p>
              In plus, ASOCIAȚIA DRAG DE BINE a desemnat un Ofiter pentru Protectia Datelor (”DPO”), care poate fi contactat daca exista
              nelamuriri cu privire la orice aspect ce tine de protectia datelor cu caracter personal prin transmiterea unei cereri scrise, 
              datate si semnate la urmatoarele date de contact:
              <br />
              Eliza Ceprăzaru, Tel. 0739 925 643, Email: dpo@dragdebine.ro.
            </p>
            <br />
            
            <p>
              Data 15.12.2022
            </p>                     
            
          </div>
        </div>
      </div>
    )
  }