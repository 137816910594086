import { Footer } from './Footer';
import Logo from "../shared/utils/images/logo_font_negru.webp";

export default function ConfirmForm() {
    return (
      <> 
      
        <div className="my-20">       
            <main className="grid min-h-full place-items-center justify-center bg-white py-24 px-6 sm:py-32 lg:px-8">
                <div class="flex flex-col text-center">
                    <img
                      className="h-[3rem] w-auto flex self-center"
                      src={Logo}
                      alt="Logo Drag de bine"                      
                    />
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Vă mulțumim</h1>
                    <p className="mt-4 text-base leading-7 text-gray-600">Formularul a fost trimis cu succes</p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                    <a href="/home" className="rounded-md bg-blueDDB-500 px-8 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blueDDB-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">Acasă</a>
                    </div>
                </div>
            </main>
        </div>

        <section className='Footer'>
        <Footer />
        </section>    

</>   
    )
}