import Landing from "../shared/utils/images/landing.webp";
import Stats from "../shared/utils/images/stats.webp";
import ITP from "../shared/utils/images/Logo ITP - Web - Secondary - Standard.png";
import Kuka from "../shared/utils/images/KUKA.png"
import Colt from"../shared/utils/images/Colt.png";
import ATPS from"../shared/utils/images/ATPS.png";
import ATF from"../shared/utils/images/Ateliere-Fără-Frontiere.png";
import { Footer } from '../components/Footer';
// import { CookiesAgree } from '../components/CookiesAgree';



const metrics = [
  { id: 1, stat: '110+', emphasis: 'Copii', rest: 'ajutați la lecții.' },
  { id: 2, stat: '180+', emphasis: 'Cadouri', rest: 'de Crăciun.' },
  { id: 3, stat: '90+', emphasis: 'Ghiozdane', rest: 'donate.' },
  { id: 4, stat: '30+', emphasis: 'Perechi de ochelari', rest: 'cumpărate.' },
]

export default function Home() {
    return (
      <>

      {/* <section className='CookiesAgree'>
        <CookiesAgree />
      </section>   */}

      <main className="mx-auto mt-40 max-w-prose lg:max-w-7xl px-4 sm:px-0">
        <div className="text-left md:text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block motto tracking-wide xl:inline">Fapte bune,</span>{' '}
            <span className="block motto tracking-wide text-pink xl:inline">făcute cu drag </span>
          </h1>                   
        </div>
      </main>


      <div className="mx-auto max-w-prose">
        <div className="rounded-md md:hidden px-4 sm:px-0 pt-2">
                <a
                  href="/Donate"
                  className="inline-flex shadow items-center justify-center rounded-md border border-transparent bg-purpleDDB-500 px-5 py-2 text-base font-medium text-white hover:bg-purpleDDB-600"
                >
                  Donează
                </a>
        </div>
      </div>
      

      <section>
      <div className="overflow-hidden bg-white">
      <div className="relative mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
        <div className="absolute top-0 bottom-0 mt-16 left-3/4 hidden w-screen bg-gray-50 lg:block" />
        <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
          <div>
            <h2 className="text-lg font-semibold text-pink">Cum îi ajutăm</h2>
            <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">Descriere scurtă</h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:col-start-2 lg:row-start-1 mt-16">
            <svg
              className="absolute top-0 right-0 -mt-20 -mr-20 hidden lg:block"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
            </svg>
            <div className="relative mx-auto max-w-prose text-base lg:max-w-none mt-0 lg:mt-6">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className="rounded-lg object-cover object-center shadow-lg"
                    src={Landing}
                    alt="Excursie Filarmonică"
                    width={1184}
                    height={1376}
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="mx-auto max-w-prose text-base leading-7 lg:max-w-none text-justify">
              <p className="text-gray-500">
                De câteva ori pe săptămână, Sorin se așază la un birou sau o măsuță, își deschide laptopul și se întâlnește online cu Denisa. 
                Fac temele împreună, dar și povestesc despre școală, despre joacă sau despre familie, despre muzică sau animalele lui din curte.   
              </p>
              <br />
              <p className="text-gray-500">
                La fel ca Sorin, asta fac și Armina, David, Iasmina, Raul, Elisa, Casiana, Marian, Darius și alți zeci de copii din diverse sate din jurul Sibiului. 
              </p>
              <br />
              <p className="text-gray-500">
                Iar la fel ca Denisa, asta fac și Luminița, Irina, Maria, Claudia, Andreea, Viorica, Mariana și alte zeci de voluntare care le dedică acestor 
                copii timpul, cunoștințele și afecțiunea lor.  
              </p>
              <br />
              <p className="text-gray-500">
                Aceste întâlniri au loc de mai bine de doi ani, timp în care am văzut cum acești copii s-au transformat sub ochii noștri și cum cresc din ce în 
                ce mai frumos – Armina citește mai bine și mai repede, Marian vede mai clar ce scrie în manual și pe tablă, Iasmina a descoperit că îi place să 
                cânte la chitară, iar David a început să picteze. Ne e drag de fiecare dintre ei, și suntem bucuroși să îi susținem și să ne lăsăm inspirați de 
                puterea lor împotriva neajunsurilor. Îi vedem cum se transformă de la o întâlnire la alta, și ne bucurăm de fiecare dată când părinții și învățătoarele 
                ne confirmă că ceea ce facem împreună se vede și contează.  
              </p>              
              <div className="mt-6 inline-flex rounded-md shadow">
                <a
                  href="/About"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-blueDDB-500 px-5 py-3 text-base font-medium text-white hover:bg-blueDDB-600"
                >
                  Citește mai mult
                </a>
            </div> 
            </div>          
          </div>          
        </div>
      </div>
      </div>
      </section>

      <section className="hidden md:block">
        <div className="bg-gray-50">
        <div className="mx-auto max-w-prose lg:max-w-7xl py-12 px-4 sm:px-0 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">Vrei să ajuți?</span>
            <span className="block text-pink">Donează sau înscrie-te ca voluntar.</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <a
                href="/Donate"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-purpleDDB-500 px-5 py-3 text-base font-medium text-white hover:bg-purpleDDB-600"
              >
                Donează
              </a>
            </div>
            <div className="ml-3 inline-flex rounded-md shadow">
              <a
                href="/Volunteer"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-purpleDDB-500 hover:bg-gray-50"
              >
                Voluntariat
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="relative bg-gray-900">
        <div className="absolute bottom-0 h-80 w-full xl:inset-0 xl:h-full">
          <div className="h-full w-full xl:grid xl:grid-cols-2">
            <div className="h-full xl:relative xl:col-start-2">
              <img
                className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                src={Stats}
                alt="Copii la filarmonică"
              />
              <div
                aria-hidden="true"
                className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
              />
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-prose px-4 sm:px-4 md:px-0 lg:max-w-7xl lg:px-8 xl:grid xl:grid-flow-col-dense xl:grid-cols-2 xl:gap-x-8">
          <div className="relative pt-12 pb-72 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
            <h2 className="text-base font-semibold text-pink">Date</h2>
            <p className="mt-3 text-3xl font-bold tracking-tight text-white">
              Cum i-am ajutat pe copii până acum
            </p>
            <p className="mt-5 text-base leading-7 text-gray-300">
              Puțin câte puțin, cu un gest mai mic sau mai mare, îi ajutăm pe copii să învețe și să crească mari, să aibă o șansă mai bună la o 
              viață ușoară și frumoasă. 
            </p>
            <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
              {metrics.map((item) => (
                <p key={item.id}>
                  <span className="block text-2xl font-bold text-white">{item.stat}</span>
                  <span className="mt-1 block text-base text-gray-300">
                    <span className="font-medium text-white">{item.emphasis}</span> {item.rest}
                  </span>
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="bg-gray-100">
        <div className="mx-auto max-w-prose lg:max-w-7xl py-16 px-4 md:px-0 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-pink">
            Cine ne sprijină?
          </h2>

          <div className="mt-8 lg:mt-10 grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 xs:grid-cols-2 gap-4">            
              <div className="mt-8 sm:mt-8 md:mt-4">
                <img className="h-10 mx-auto" src={ITP} alt="IT Perspectives" />
                <p className="text-base font-normal text-gray-400 mt-3 text-center">Powered by</p>
              </div>
              <div className="mt-8 sm:mt-8 md:mt-4">
                <img className="h-8 mt-2 mx-auto" src={Kuka} alt="KUKA" />
                <p className="text-base font-normal text-gray-400 mt-3 text-center">Sponsor</p>
              </div>
              <div className="mt-8 sm:mt-8 md:mt-4">
                <img className="h-10 sm:h-10 md:h-12 lg:h-10 mx-auto" src={Colt} alt="Colt" />
                <p className="text-base font-normal text-gray-400 mt-3 text-center">Sponsor</p>
              </div>
              <div className="mt-8 sm:mt-8 md:mt-4">
                <img className="h-12 sm:h-12 md:h-12 lg:h-20 mx-auto" src={ATPS} alt="Mirage" />
                <p className="text-base font-normal text-gray-400 mt-3 text-center">Partener</p>
              </div>    
              <div className="mt-8 sm:mt-8 md:mt-4">
                <img className="h-20 mx-auto" src={ATF} alt="Ateliere Fără Frontiere" />
                <p className="text-base font-normal text-gray-400 mt-3 text-center">Donator</p>
              </div>         
          </div>
        </div>
      </div>
    </section>

    <section className='Footer'>
      <Footer />
    </section>      

      </>
    )
  }