import React, { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export const VolunteerForm = () => {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sendStatus, setSendStatus] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const isDisabled = sendStatus === "loading";
  const { executeRecaptcha } = useGoogleReCaptcha();

  const postData = async (e) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha("contact");

    fetch(`${process.env.REACT_APP_API}/voluntariat`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        lastName,
        email,
        phone,
        message,
        token,
      }),
    })
      .then((res) => res.json())
      .then(() => {
        window.location.href = "/ConfirmForm";
      })
      .catch(() => {
        setSendStatus("error");
      });
  };
  return (
    <div className="form-wrapper">
      <form
        className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8 contact"
        onSubmit={(e) => {
          postData(e);
          setSendStatus("loading");
        }}
      >
        <div>
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700"
          >
            Nume
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="first-name"
              id="first-name"
              autoComplete="given-name"
              className="block w-full rounded-md bg-white border-gray-300 py-3 px-4 shadow-sm focus:border-blueDDB-500 focus:ring-blueDDB-500"
              onChange={(e) => setName(e.target.value)}
              value={name}
              required
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="last-name"
            className="block text-sm font-medium text-gray-700"
          >
            Prenume
          </label>
          <div className="mt-1">
            <input
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              required
              type="text"
              name="last-name"
              id="last-name"
              autoComplete="family-name"
              className="block w-full rounded-md bg-white border-gray-300 py-3 px-4 shadow-sm focus:border-blueDDB-500 focus:ring-blueDDB-500"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <div className="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              className="block w-full rounded-md bg-white border-gray-300 py-3 px-4 shadow-sm focus:border-blueDDB-500 focus:ring-blueDDB-500"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="telefon"
            className="block text-sm font-medium text-gray-700"
          >
            Telefon
          </label>
          <div className="mt-1">
            <input
              id="telefon"
              name="phone"
              type="text"
              autoComplete="telephone"
              className="block w-full rounded-md bg-white border-gray-300 py-3 px-4 shadow-sm focus:border-blueDDB-500 focus:ring-blueDDB-500"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              required
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="message"
            className="block text-sm font-medium text-gray-700"
          >
            Mesaj
          </label>
          <div className="mt-1">
            <textarea
              id="message"
              name="message"
              rows={4}
              className="block w-full rounded-md bg-white border-gray-300 py-3 px-4 shadow-sm focus:border-blueDDB-500 focus:ring-blueDDB-500"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              required
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <div className="relative flex items-start">
            <div className="flex h-5 items-center">
              <input
                id="comments"
                aria-describedby="comments-description"
                name="comments"
                checked={termsAndConditions}
                onChange={(e) => setTermsAndConditions(e.target.checked)}
                required
                type="checkbox"
                className="h-4 w-4 rounded bg-white border-gray-300 text-blueDDB-500 focus:ring-blueDDB-600"
              />
            </div>
            <div className="ml-3 text-sm">
              <p>
                Sunt de acord cu
                <a href="/Legal/TermsAndConditions"> termenii și condițiile </a>
                prevăzute de acordul de utilizare.
              </p>
            </div>
          </div>
        </div>
        <div className="sm:col-span-2">
          <div className="rounded-md shadow">
            {isDisabled ? (
              <button
                disabled
                type="button"
                className="items-center w-full inline-flex justify-center rounded-md border border-transparent bg-blueDDB-500 py-3 px-6 text-base font-medium text-white shadow-sm hover:bg-blueDDB-600 focus:outline-none focus:ring-2 focus:ring-blueDDB-500 focus:ring-offset-2 contact"
              >
                <svg
                  aria-hidden="true"
                  role="status"
                  class="inline w-4 h-4 mr-3 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Cererea de înscriere este în curs de trimitere...
              </button>
            ) : (
              <button
                href="#contactForm"
                className="flex w-full items-center justify-center rounded-md border border-transparent bg-blueDDB-500 px-5 py-3 text-base font-medium text-white hover:bg-blueDDB-600 contact"
              >
                Înscrie-te ca voluntar
              </button>
            )}
          </div>
          {sendStatus === "error" && (
            <div className="message-error">
              <p>
                O eroare a avut loc și cererea dumneavoastră de înscriere nu a
                fost trimisă.
              </p>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};
