import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import NavigationMenu from "./components/NavigationMenu";
import Home from "./pages/Home";
import About from "./pages/About";
import Superheroes from "./pages/Superheroes";
import News from "./pages/News";
import Volunteer from "./pages/Volunteer";
import Contact from "./pages/Contact";
import Donate from "./pages/Donate";
import TermsAndConditions from "./pages/Legal/TermsAndConditions";
import CookiePolicy from "./pages/Legal/CookiePolicy";
import PrivacyPolicy from "./pages/Legal/PrivacyPolicy";
import RedirectTax from "./pages/Donate/RedirectTax";
import RedirectIncomeTax from "./pages/Donate/RedirectIncomeTax";
import ConfirmForm from "./components/ConfirmForm";

const AppRoutes = () => {
  return (
    <>
      <NavigationMenu />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Superheroes" element={<Superheroes />} />
        <Route path="/News" element={<News />} />
        <Route path="/Volunteer" element={<Volunteer />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Donate" element={<Donate />} />
        <Route
          path="/Legal/TermsAndConditions"
          element={<TermsAndConditions />}
        />
        <Route path="/Legal/CookiePolicy" element={<CookiePolicy />} />
        <Route path="/Legal/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Legal/RedirectTax" element={<RedirectTax />} />
        <Route
          path="/Legal/RedirectIncomeTax"
          element={<RedirectIncomeTax />}
        />
        <Route path="/ConfirmForm" element={<ConfirmForm />} />
        <Route path="../sitemap.xml" />
      </Routes>
    </>
  );
};

export default AppRoutes;
