import { useEffect } from "react";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import ReactGA4 from "react-ga4";
import React from "react";
import useGoogleCookiesTracker from "../hooks/useGoogleEventTracker";

export const CookiesForm = () => {
  const handleAcceptCookie = () => {
    
    ReactGA4.initialize(`${process.env.REACT_APP_PUBLIC_GOOGLE_ANALYTICS_ID}`);
    ReactGA4._gtag(
      "config",
      `${process.env.REACT_APP_PUBLIC_GOOGLE_ANALYTICS_ID}`,
      {
        cookie_expires: 28 * 24 * 60 * 60,
        cookie_update: false,
      }
    );
  };

  const handleDeclineCookie = () => {
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);
  const GAEventsTracker = useGoogleCookiesTracker(
    "Button",
    "Button - Home - Cookie Policy",
    "Cookie policy button"
  );

  return (
    <CookieConsent
      buttonText="Accept cookies"
      declineButtonText="Decline cookies"
      enableDeclineButton
      onAccept={handleAcceptCookie}
      onDecline={handleDeclineCookie}
      expires={30}
      visible={"byCookieValue"}
    >
      This website uses cookies to enhance the user experience.{" "}
      <a
        href="/Cookies"
        target="_blank"
        rel="noreferrer"
        className="cookie-link"
        onClick={GAEventsTracker}
      >
        Cookies Policy.
      </a>
    </CookieConsent>
  );
};