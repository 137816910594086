import { Footer } from '../components/Footer';
import Caravana from "../shared/utils/images/caravana-optometrie.webp";
import Ghiozdane from "../shared/utils/images/ghiozdane.webp";
import Muzica from "../shared/utils/images/muzica.webp";

export default function AboutUs() {
  return (
    <>  
    <div className="relative overflow-hidden bg-white mt-40 pb-16">
      <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
        <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
          <svg
            className="absolute top-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg
            className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg
            className="absolute bottom-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
      <div className="mx-auto max-w-prose lg:max-w-7xl space-y-8 px-4 sm:px-6 md:px-0 lg:px-8">
        <div className="mx-auto max-w-none text-base">
          <h2 className="text-lg font-semibold text-pink">Cine suntem</h2>
          <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            Despre noi
          </p>
        </div>
        
        <p className="mt-8 prose-lg text-base leading-7 text-gray-500">
          Asociația Drag de bine ajută copiii din mediile rurale să aibă o șansă mai bună la o creștere frumoasă. Le facilităm accesul la educație, școală și 
          informație, le stimulăm dezvoltarea personală și educația non-formală, și le îngrijim sănătatea, atât cea fizică cât și pe cea mintală.
        </p>

        <div className="mt-16 grid grid-cols-1 gap-y-12 sm:gap-y-16 lg:grid-cols-2 lg:gap-x-8">
          <div>
            <figure>
            <div className="aspect-w-3 aspect-h-2 w-full overflow-hidden rounded-lg">
              <img
                src={Muzica}
                alt="Muzică, Săcel"
                className="h-full w-full object-cover object-center"
              />
            </div>              
            </figure>
          </div>
          <div>
            <figure>
              <div className="aspect-w-3 aspect-h-2 w-full overflow-hidden rounded-lg">
                <img
                  src={Ghiozdane}
                  alt="Ghiozdane, Săcel"
                  className="h-full w-full object-cover object-center"
                />
              </div>              
            </figure>
          </div>
        </div>      

        <div className="prose text-base leading-7 mx-auto mt-6 text-gray-500 text-justify">
          <p>
            Acum doi ani, IT Perspectives a început să se implice direct în accesul copiilor la educație, începând 
            cu câteva sate din jurul Sibiului. Le-am donat laptopuri și, împreună cu Asociația Tinerilor Pedagogi din 
            Sibiu, am reușit ca fiecare copil să aibă o „doamnă” a lui, studentă la Pedagogia Învățământului Primar 
            și Preșcolar, cu care se întâlnește online de două ori pe săptămână și fac temele împreună. Dar voluntarele 
            noastre fac mai mult decât atât – le-au devenit prieteni, confidenți și cei mai mari susținători. Aceste 
            întâlniri continuă și acum, chiar dacă școala a revenit în sălile de clasă. Multe dintre voluntare se văd 
            deja de trei ani cu copilașii lor, și își păstrează încă locul cu adevărat special din inimile lor.
          </p>
          <p>
            Mai mult decât un laptop și un sprijin la lecții, prin Asociația Drag de bine le putem oferi copiilor haine, 
            mâncare, rechizite, cadouri, consultații medicale sau oftalmologice, precum și o pereche de ochelari 
            unde a fost cazul. Binele e de mai multe feluri, iar acesta arată diferit de la un caz la altul. 
            Un bine făcut poate fi o haină, o prietenie sinceră sau un consult medical. Se poate face mai ușor sau mai 
            greu, durează mai mult sau mai puțin, e acceptat repede, primit cu reticență sau uneori chiar refuzat.
          </p>
          <p>
            O lume mai blândă și mai frumoasă pentru copii nu se construiește peste noapte, ci puțin câte puțin - cu 
            fiecare întâlnire în care a învățat ceva și s-a simțit ascultat, și cu fiecare zâmbet de pe față atunci 
            când primește ceva și se simte important. Vă invităm să facem fapte bune împreună, de dragul binelui! 
          </p>         
        </div>

        <div className="mx-auto max-w-7xl space-y-8">
          <figure>
            <div className="aspect-w-3 aspect-h-1 w-full overflow-hidden rounded-lg">
              <img
                src={Caravana}
                alt="Caravana Optometrie."
                className="h-full w-full object-cover object-center"
              />
            </div>             
          </figure>
        </div>         
    
      </div>
    </div>

    <section className='Footer'>
      <Footer />
    </section>    
  </>   
  )
}