export default function CookiePolicy() {
    return (
      <div className="relative overflow-hidden bg-white pt-40 pb-16">        
        <div className="relative px-4 sm:px-6 lg:px-8">
         <div className="mx-auto max-w-7xl space-y-8 px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-prose text-base lg:max-w-none">
            <h2 className="text-lg font-semibold text-pink">Regulament</h2>
            <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Politica de cookies
            </p>
          </div>
          </div>
          <div className="prose prose-lg max-w-7xl py-16 px-4 sm:px-6 lg:px-8 prose-indigo mx-auto mt-6 text-gray-500">
            <h2>1. Introducere</h2>
            <p>
                Această politică se referă la cookie-uri și pagini web operate de ASOCIAȚIA DRAG DE BINE cu sediul social în
                Sibiu, Str. general Dragalina, nr. 1, jud. Sibiu.                   
            </p>
            <p>
                La ASOCIAȚIA DRAG DE BINE credem că suntem transparenți și deschiși cu privire la modul în care colectăm și 
                utilizăm datele dumneavoastră cu caracter personal. În spiritul transparenței, această politică oferă informații 
                detaliate despre cum și când folosim cookie-urile. Această politică privind cookie-urile se aplică oricărui serviciu
                 disponibil pe Site, pentru a permite clientului să vizualizeze informațiile de pe site folosind exclusiv 
                 mijloace electronice, inclusiv alte mijloace de comunicare la distanță (inclusiv telefon) care este legată 
                 de această politică sau care o încorporează de referință.            
            </p>
            <p>
                ASOCIAȚIA DRAG DE BINE se obligă să vă protejeze cu privire la colectarea online de pe site-ul său 
                www.dragdebine.ro a oricăror date despre dumneavoastră. Această secțiune vă va spune cum folosim cookie-urile, 
                de ce și cum ne permite acest lucru să ne îmbunătățim serviciile. De asemenea, vă va spune cum să gestionați 
                cookie-urile care sunt stocate pe dispozitivul dvs.            
            </p>


            <h2>2. ASOCIAȚIA DRAG DE BINE folosește cookie-uri?</h2>
            <p>
                Folosim cookie-uri pentru a îmbunătăți utilizarea și funcționalitatea site-ului nostru și pentru a înțelege mai 
                bine modul în care vizitatorii folosesc site-ul ASOCIAȚIA DRAG DE BINE, precum și serviciile oferite de site.                   
            </p>
            <p>
                Dacă nu sunteți de acord cu utilizarea cookie-urilor, trebuie să setați în mod corespunzător setările browserului 
                sau să nu utilizați acest site web sau alte site-uri web partenere.           
            </p>
            <p>
                Dezactivarea cookie-urilor pe care le folosim ar putea afecta experiența dumneavoastră ca utilizator pe acest site 
                web și pe alte site-uri web partenere. Ne rezervăm dreptul de a aduce modificări Politicii cookie. Toate 
                modificările vor apărea aici și vor intra în vigoare imediat. Utilizarea în continuare a site-ului nostru 
                web reprezintă acordul dumneavoastră cu privire la orice modificări.            
            </p>
            

            <h2>3. Ce este un cookie?</h2>
            <p>
                ✓ Un cookie este o colecție de date într-un standard industrial pe care o pagină web le transferă pe computerul 
                dumneavoastră, temporar, pentru a permite adaptarea conținutului la utilizator. În acest scop, cookie-urile 
                colectează informații despre comportamentul dumneavoastră online.            
            </p>
            <p>
                ✓ Un cookie este un fișier mic care conține un text format dintr-un șir de litere și cifre (informații), adesea 
                codificate, care va identifica în mod unic computerul, terminalul mobil sau alte echipamente ale unui utilizator 
                de pe care poate fi accesat internetul, dar poate contine si alte informatii. Un cookie este format din două 
                părți: numele și conținutul sau valoarea cookie-ului. Durata unui cookie este determinată.
            </p>
            <p>
                ✓ Un cookie este un fișier care este plasat/descărcat pe dispozitivul pe care îl utilizați atunci când accesați un
                site web. Se emite o solicitare de către serverul web către un browser (ex. Internet Explorer, Google Chrome),
                care permite ASOCIAȚIA DRAG DE BINE să înregistreze navigarea pe site, astfel încât, atunci când accesați din
                nou site-ul, acesta poate prezenta opțiuni adaptate pe baza informațiile stocate despre ultima dvs. vizită.
            </p>
            <p>
                ✓ Un cookie este complet „pasiv” (nu conține software, viruși - cookie-urile folosesc formate de text simplu, nu 
                sunt alcătuite din bucăți de cod, așa că nu pot fi executate și nu pot fi rulate automat; nu pot fi duplicate sau 
                replicate în alte rețele pentru a rula sau replica - sau spyware și nu poate accesa informații de pe hard disk-ul 
                utilizatorului), permițând doar colectarea de informații despre comportamentul utilizatorului (de obicei, ce 
                pagini web vizitează și când).
            </p>
            <p>
                ✓ Cu alte cuvinte, modulul cookie poate fi văzut ca o carte de identificare a utilizatorului de internet, care anunță
                site-ul de fiecare dată când utilizatorul revine pe acel site.
            </p>


            <h2>4. De ce sunt cookie-urile importante pentru internet?</h2>
            <p>
                Cookie-urile reprezintă punctul focal al funcționării eficiente a Internetului, ajutând la generarea unei 
                experiențe de navigare prietenoase și adaptată preferințelor și intereselor fiecărui utilizator. Refuzarea sau 
                dezactivarea cookie-urilor poate face ca unele site-uri web să fie imposibil de utilizat. Respingerea sau 
                dezactivarea cookie-urilor nu înseamnă că nu veți mai primi publicitate online, ci doar că aceasta nu va mai 
                putea ține cont de preferințele și interesele dumneavoastră, evidențiate de comportamentul dumneavoastră de navigare.
            </p>
            <p>
                Exemple de utilizări importante ale cookie-urilor (care nu necesită autentificarea unui utilizator printr-un cont):
            </p>
            <p>
                ✓ Conținut și servicii adaptate preferințelor utilizatorului
            </p>
            <p>
                ✓ Memorarea parolelor, preferințelor de limbă (de exemplu: afișarea rezultatelor căutării în limba română)
            </p>
            <p>
                ✓ Reținerea filtrelor de protecție a copiilor privind conținutul de pe internet (opțiuni pentru modul familie, 
                funcții de căutare sigură)
            </p>
            <p>                
                ✓ Limitați frecvența difuzării anunțurilor - Limitați numărul de afișări ale unui anunț pentru un anumit 
                utilizator pe un site web
            </p>
            <p>                
                ✓ Oferirea de publicitate mai relevantă pentru utilizator
            </p>
            <p>
                ✓ Funcții de măsurare, optimizare și analiză - cum ar fi confirmarea unui anumit nivel de trafic către un site 
                web, ce tip de conținut este vizualizat și modul în care un utilizator ajunge la un site web (de exemplu, prin 
                motoarele de căutare, direct de pe alte site-uri web etc.). Site-urile web efectuează aceste analize ale 
                utilizării lor pentru a îmbunătăți site-urile web în beneficiul utilizatorilor.
            </p>


            <h2>5. Scopul cookie-urilor</h2>
            <p>
                Cookie-urile pot oferi o interacțiune mai rapidă și mai ușoară între utilizatori și site-uri web. De exemplu, 
                atunci când un utilizator se conectează la un anumit site web, acreditările sale sunt stocate într-un cookie; ulterior, 
                utilizatorul poate accesa site-ul respectiv fără a fi nevoie să se autentifice din nou.
            </p>
            <p>
                În alte cazuri, cookie-urile pot fi folosite pentru a stoca informații despre activitățile efectuate de utilizator pe o 
                anumită pagină web, astfel încât acesta să poată relua cu ușurință acele activități atunci când site-ul este accesat 
                ulterior. Cookie-urile îi spun serverului ce pagini să arate utilizatorului, astfel încât să nu fie nevoit să-și 
                amintească acest lucru sau să navigheze pe întregul site de la început. Astfel, cookie-urile pot fi asimilate 
                unor „marcaje” care îi spun utilizatorului exact unde a rămas acesta într-un site web.
            </p>

            <h2>6. Ce tipuri de cookie-uri folosim</h2>
            <p>
                Utilizăm următoarele tipuri de cookie-uri:
            </p>                
            <p>
                • Cookie-uri de performanță a site-ului web
            </p>
            <p>
                • Cookie-uri de analiză a utilizatorilor
            </p>                
            <p>
                • Cookie-uri de geotargeting
            </p>                
            <p>
                • Cookie-uri pentru publicitate
            </p>                
            <p>
                Pentru informații complete, vă recomandăm să consultați Politica de confidențialitate a ASOCIAȚIA DRAG DE BINE, 
                iar pentru orice întrebări suplimentare referitoare la modul în care sunt utilizate cookie-urile prin intermediul 
                site-ului nostru, vă rugăm să ne trimiteți un email la adresa de eliza@dragdebine.ro. Vă vom analiza 
                solicitarea și vă vom trimite răspunsul în termenul legal.
            </p>
            <p>
                Cookie-urile persistente ne ajută să vă recunoaștem ca utilizator existent, ceea ce vă facilitează întoarcerea la ASOCIATIA DRAG DE BINE sau interacțiunea cu serviciile noastre. Cookie-urile de sesiune sunt active numai în timpul unei 
                sesiuni (de obicei, o vizită obișnuită a site-ului web sau o sesiune de browser).
            </p> 
            <table>
                <tr>
                    <th>Categorii de utilizare</th>
                    <th>Descriere</th>
                </tr>
                <tr>
                    <td>Siguranta/Securitate</td>
                    <td>
                        Noi utilizam module cookie ca sa activam si sa sprijinim functionalitatile noastre de siguranta, precum si ca 
                        sa detectam activitati daunatoare sau incalcari ale Acordului utilizatorului. Folosim module cookie pentru a 
                        detecta fraudele si utilizarea abuziva a website-urilor si a serviciilor noastre.
                    </td>
                </tr>
                <tr>
                    <td>Retele sociale</td>
                    <td>
                        Website-ul nostru poate include module cookie de retele sociale (Facebook, Instagram), inclusiv pe cele care 
                        le permit utilizatorilor conectati la serviciul de retele sociale sa partajeze continut prin acel serviciu.
                    </td>
                </tr>
                <tr>
                    <td>Preferinte, functionalitati si servicii</td>
                    <td>
                        Preferinte, functionalitati si servicii	Modulele cookie ne pot indica ce limba preferati si care sunt 
                        preferintele dumneavoastra de comunicare. Modulele cookie va pot facilita completarea formularelor pe website-ul 
                        ASOCIAȚIA DRAG DE BINE. De asemenea, modulele cookie va pot furniza functionalitati, informatii si continut 
                        personalizat cu ajutorul pluginurilor noastre. Setarile care permit produselor noastre sa opereze corect sau care 
                        pastreaza preferintele dumneavoastra in timp pot fi stocate pe dispozitiv. De asemenea, salvam preferintele, 
                        cum ar fi limba, browserul si setarile de player multimedia, astfel incat sa nu fie necesar sa fie resetate de 
                        fiecare data cand va intoarceti la website. Daca renuntati la publicitatea bazata pe interese, stocam aceasta preferinta 
                        de renuntare utilizand un modul cookie pe dispozitivul dumneavoastra. 
                    </td>
                </tr>
                <tr>
                    <td>Publicitate/Publicitate bazata pe categorii de interese/modul cookie-uri ale furnizorilor de publicitate</td>
                    <td>
                        Noi putem utiliza module cookie ca sa afisam reclame relevante pentru dumneavoastra., atat pe website-ul 
                        ASOCIAȚIA DRAG DE BINE cat si in afara acestuia. Putem utiliza, de asemenea, un modul cookie pentru a afla daca 
                        cineva care a vazut o reclama a accesat ulterior website-ul beneficiarului de publicitate si a efectuat o actiune 
                        (de ex., a descarcat o carte alba sau a efectuat o achizitie). In mod similar, partenerii nostri pot utiliza module 
                        cookie ca sa afle daca am afisat o reclama si care a fost performanta acesteia sau sa ne furnizeze informatii despre 
                        modul in care interactionati cu reclamele. Putem, de asemenea, sa colaboram cu un partener ca sa va afisam o reclama 
                        pe sau in afara website-ului patenerului, de exemplu, dupa ce ati vizitat website-ul sau aplicatia partenerului 
                        respectiv. ASOCIAȚIA DRAG DE BINE utilizeaza module cookie pentru a colecta date despre activitatea dumneavoastra 
                        online si a va identifica interesele, astfel incat sa va putem prezenta reclamele cele mai relevante. Puteti renunta 
                        la primirea publicitatii bazate pe categorii de interese de la alte website-uri.
                    </td>
                </tr>
                <tr>
                    <td>Afisarea publicitatii</td>
                    <td>
                        ASOCIAȚIA DRAG DE BINE utilizeaza module cookie pentru a inregistra numarul vizitatorilor care au facut clic pe o 
                        reclama si pentru a inregistra ce reclame ati vazut, ca sa nu vedeti aceeasi reclama in mod repetat.
                    </td>
                </tr>
                <tr>
                    <td>Performanta, analize si cercetare/Statistici</td>
                    <td>
                        Modulele cookie ne ajuta sa determinam performanta website-ului si a pluginurilor noastre in diferite locatii. 
                        De asemenea, noi utilizam module cookie ca sa intelegem, sa optimizam si sa cercetam produse, functionalitati si 
                        servicii, inclusiv atunci cand dumneavoastra accesati ASOCIAȚIA DRAG DE BINE de pe alte website-uri, aplicatii 
                        sau dispozitive, cum ar fi de pe computerul dumneavoastra de la serviciu sau de pe dispozitivul dumneavoastra mobil. 
                        ASOCIAȚIA DRAG DE BINE utilizeaza module cookie pentru echilibrarea incarcaturii, pentru a se asigura ca 
                        website-urile raman in stare de functionare. Pentru a va furniza produsele noastre, folosim module cookie si alti 
                        identificatori pentru a colecta date despre utilizare si performanta. De exemplu, folosim modulele cookie pentru a 
                        contoriza numarul de vizitatori unici ai unei pagini web sau ai unui serviciu si pentru a crea alte statistici despre 
                        operatiunile produselor noastre. Printre acestea se numara modulele cookie de la parteneri si de la furnizorii terti 
                        de analiza.
                    </td>
                </tr>
            </table>


            <h2>7. Cand se plaseaza modulele cookie?</h2>
            <p>
                Toate browserele care acceseaza acest website vor primi module cookie de la noi. Plasam, de asemenea, module cookie in browser 
                atunci cand accesati website-uri care nu folosesc module cookie si care ne gazduiesc plugin-urile sau etichetele.
            </p>


            <h2>8. Care este durata de viata a unui modul cookie?</h2>
            <p>
                Durata de viata a unui modul cookie poate varia semnificativ, depinzand de scopul pentru care este plasat. Un modul cookie per 
                sesiune va expira la inchiderea sesiunii, iar un modul cookie permanent nu va expira pe o anumita perioada. Durata de viata a unui 
                modul cookie nu ii este impusa niciunui utilizator. Ele pot fi blocate sau sterse in orice moment prin intermediul setarilor browserului.
            </p>


            <h2>9. Contin modulele cookie date personale/Ce tip de informatii sunt stocate si accesate prin intermediul modulelor cookie</h2>
            <p>
                Modulele cookie in sine nu solicita si nu detin date cu caracter personal. Ele nu pot identifica personal un utilizator de internet. 
                Datele personale pot fi colectate prin utilizarea modulelor cookie doar pentru a facilita anumite functionalitati pentru utilizator, 
                imbunatatind experienta de navigare pe Internet (de exemplu: setarile limbii in care se doreste accesarea unui website; pastrarea unui 
                user logat in contul de webmail, securitatea online banking, pastrarea produselor in cosul de cumparaturi). Pentru detalii legate de 
                prelucrarea datelor dumneavoastra cu caracter personal, va rugam sa consultati Politica de confidentialitate disponibila la urmatorul 
                link:  ……......…… In cazul in care un modul cookie prelucreaza date cu caracter personal, ne vom asigura ca prelucrarea se va face 
                in conformitate cu legislatia in vigoare si cu Regulamentul (UE) nr. 679/2016 (GDPR).
            </p>


            <h2>10. Ce sunt modulele cookie plasate de terti? („third party cookies”)</h2>
            <p>
                In afara de modulele cookie pe care ASOCIAȚIA DRAG DE BINE le creeaza atunci cand vizitati website-ul nostru, si tertii pot sa 
                creeze module cookie in timp ce vizitati website-urile tertilor. In unele cazuri, aceasta se intampla deoarece am angajat tertii 
                pentru a furniza servicii in numele nostru, cum ar fi analiza website-urilor. In alte cazuri, paginile noastre web ofera continut 
                sau reclame de la terti, cum ar fi videoclipuri, stiri sau reclame livrate de alte retele de publicitate. Deoarece browserul 
                dumneavoastra se conecteaza la serverele web ale acestor terti pentru a prelua continutul, tertii respectivi pot crea sau citi 
                propriile modulele cookie de pe dispozitivul dumneavoastra si pot sa colecteze informatii despre activitatile dumneavoastra online,
                pe diferite website-uri sau servicii online. Tertele parti/Furnizorii terti trebuie sa respecte de asemenea legea in vigoare si 
                politicile de confidentialitate ale detinatorului (proprietarului) website-ului.
            </p>


            <h2>11. Ce entitate utilizeaza module cookie?</h2>
            <p>
                Daca sunteti rezident pe teritoriul UE, ASOCIAȚIA DRAG DE BINE va fi responsabil cu furnizarea catre sau colectarea datelor dumneavoastra 
                personale de catre sau pentru serviciile noastre. Daca sunteti rezident intr-un stat tert, non-UE, ASOCIAȚIA DRAG DE BINE va fi 
                responsabil cu furnizarea catre sau colectarea datelor dumneavoastra personale de catre sau pentru serviciile noastre.
            </p>


            <h2>12. Controlul modulelor cookie</h2>
            <p>
                In general, o aplicatie folosita pentru accesarea paginilor web permite salvarea modulelor cookie pe terminal in mod implicit. Aceste setari 
                pot fi schimbate in asa fel incat administrarea automata a modulelor cookie sa fie blocata de browserul web sau utilizatorul sa fie informat 
                de fiecare data cand modulele cookie sunt trimise catre terminalul sau. Informatii detaliate despre posibilitatile si modurile de administrare 
                a modulelor cookie pot fi gasite in zona de setari a aplicatiei (browser-ului web). Limitarea folosirii modulelor cookie poate afecta anumite 
                functionalitati ale paginii web. De asemenea, este posibil ca aceasta sa va impiedice sa salvati setarile dumneavoastra personalizate, cum ar 
                fi informatiile de intrare in cont.
            </p>
            <p>
                In plus, anumite caracteristici ale produselor ASOCIAȚIA DRAG DE BINE depind de modulele cookie. Spre exemplu, daca optati pentru 
                blocarea modulelor cookie, s-ar putea sa nu reusiti sa utilizati acele functii si preferintele care depind de modulele cookie se pot pierde. 
                Daca decideti sa stergeti modulele cookie, setarile si preferintele controlate de acestea, inclusiv cele legate de reclame, vor fi sterse 
                si poate fi necesara recreerea acestora. Instructiunile pentru blocarea sau stergerea modulelor cookie din browsere pot fi disponibile 
                in documentatia fiecarui browser privind confidentialitatea sau de ajutor.
            </p>

            <h2>13. Ce aveti de facut daca nu doriti setarea modulelor cookie sau daca doriti eliminarea acestora?</h2>
            <p>
                Daca nu doriti ca noi sa utilizam module cookie cand vizitati ASOCIAȚIA DRAG DE BINE, puteti sa renuntati la unele dintre ele din pagina 
                dumneavoastra de setari. Pentru ca sa recunoastem ca ati renuntat la modulele cookie, noi trebuie sa plasam modulul nostru cookie de renuntare 
                pe dispozitivul dumneavoastra, ca sa stim sa nu folosim alte module cookie cand vizitati din nou ASOCIAȚIA DRAG DE BINE. Daca nu doriti 
                sa acceptati module cookie, puteti, de asemenea, sa modificati setarile browserului pe computerul dumneavoastra sau pe alte dispozitive utilizate 
                ca sa accesati serviciile noastre. Daca utilizati ASOCIAȚIA DRAG DE BINE fara sa modificati setarile browserului dumneavoastra, vom presupune 
                ca doriti sa acceptati toate modulele cookie pe website-ul nostru. Majoritatea browserelor asigura de asemenea functionalitatea care va permite 
                sa analizati si sa stergeti module cookie. Website-ul ASOCIAȚIA DRAG DE BINE nu va functiona adecvat fara module cookie. Producatorii de 
                browsere asigura pagini de asistenta privind gestionarea modulelor cookie in produsele lor. Pentru mai multe informatii, vizualizati mai jos.
            </p>
            <p>
                ✓	Google Chrome <br />
                ✓	Internet Explorer <br />
                ✓	Mozilla Firefox <br />
                ✓	Safari (Desktop) <br />
                ✓	Safari (Mobile) <br />
                ✓	Browser Android <br />
                ✓	Opera <br />
                ✓	Opera Mobile <br />
            </p>
            <p>
                Pentru alte browsere, va rugam sa consultati documentatia furnizata de producatorul browserului. Pentru a afla mai multe despre modulele cookie, 
                inclusiv cum sa vizualizati tipurile de module cookie care au fost instalate si cum sa le administrati si sa le stergeti, vizitati wikipedia.org, 
                http://www.youronlinechoices.com/ro, www.aboutmodul cookies.org sau http://www.allaboutmodul cookies.org/.
            </p>


            <h2>14. Modalitati concrete de stergere a modulelor cookie:</h2>
            <p className="font-bold">
                a. Pe computer
            </p>
            <p>
               <span className="font-bold"> Browserul Apple Safari: </span> Sectiunea „Confidentiality” (confidentialitate) din meniul «Preferences» (preferinte), sectiunea prevazuta pentru „Modul 
                cookies and other data of Internet websites” (modul cookie si alte date provenite de la website-uri).
            </p>
            <p>
               <span className="font-bold"> Browserul Google Chrome: </span> Sectiunea „Confidentiality” (confidentialitate) din meniul «Parameters» (parametri), subsectiunea «Content Parameters» 
               (parametri de continut), sectiunea prevazuta pentru «Modul cookies and website data» (modul cookie si date provenite de la website-uri).
            </p>
            <p>
               <span className="font-bold"> Browserul Microsoft Internet Explorer: </span> Meniul „Tools” (instrumente), apoi sectiunea „Internet Options” (optiuni de internet), „General” 
               (generalitati), „Browser history” (istoric al browserului), „Parameters” (parametri), apoi deschideti fereastra „Parameters of temporary internet files and history” 
               (parametri ai fisierelor temporare de internet si ai istoricului) si in final „Display files” (afisare a fisierelor).
            </p>
            <p>
               <span className="font-bold"> Browserul Mozilla Firefox: </span> Meniul „Tools” (instrumente), sectiunea „Optiuni” (optiuni), subsectiunea „Privacy” (confidentialitate), 
               functionalitatea „Delete specific modul cookies” (stergerea modulelor ookie specifice).
            </p>
            <br />
            <p className="font-bold">
                b. Pe smartphone sau tableta
            </p>
            <p>
                Puteti sa dezinstalati aceste module cookie folosind urmatoarele metode:
            </p>
            <p>
               <span className="font-bold"> Browser Android: </span> Deschideti ecranul „Home” (pagina de pornire), atingeti pictograma browserului web, atingeti butonul „Menu” (meniu), 
               atingeti „Settings” (setari). Dispozitivul va va transfera la un meniu cu setarile disponibile SAU va avea una din cele trei optiuni urmatoare. Atingeti optiunea si 
               veti avea: Privacy & Security (confidentialitate si securitate), Privacy (confidentialitate), optiunea Security (securitate), atingeti „Clear Cache” (golire cache), 
               atingeti „OK” pentru a confirma, atingeti „Clear all cookie data” (stergerea tuturor datelor modulelor cookie), atingeti „OK” pentru a confirma. Faceti clic pe butonul 
               „Home” (pagina de pornire) pentru a reveni la ecranul „Home” (pagina de pornire). Reporniti dispozitivul oprindu-l si pornindu-l din nou.
            </p>
            <p>
               <span className="font-bold"> Browser Google Chrome: </span> : In bara de instrumente a browserului, faceti clic pe „the Chrome menu” (meniul Chrome), apoi faceti clic pe 
               „More tools” (mai multe instrumente) si „Clear browsing data” (stergerea datelor de navigare). In caseta care apare, faceti clic pe casetele de selectare pentru „cookies 
               and other website and plug-in data” (module cookie si alte date ale website-ului si plug-in-ului) si „Cached images and files” (imagini si fisiere memorate in cache). 
               Folositi meniul din partea de sus pentru a selecta volumul de date pe care doriti sa il stergeti. Alegeti „beginning of time” (de la inceput) pentru a sterge totul. 
               Faceti clic pe „Clear browsing data” (stergerea datelor de navigare).
            </p>
            <p>
               <span className="font-bold"> Apple iOS 8 sau versiuni mai noi: </span> :Faceti clic pe butonul „Home” (pagina de pornire), atingeti butonul „Settings” (setari), 
               derulati in jos pana la „Safari”, atingeti „Clear History and Website Data” (stergerea istoricului si a datelor website-urilor). Veti primi un mesaj de avertizare. 
               Atingeti „Clear History and Data” (stergerea istoricului si a datelor) pentru a confirma. Faceti clic pe butonul „Home” (pagina de pornire) pentru a reveni la 
               ecranul „Home” (pagina de pornire). Reporniti dispozitivul oprindu-l si pornindu-l din nou.
            </p>
            <br /><br />
            <p>
               <span className="font-bold"> Foarte important: </span> Procedura de stergere a modulelor cookie poate diferi in functie de versiunea de software. Pentru mai 
               multe detalii contactati operatorul de software in functie de problemele pe care le intampinati.
            </p>


            <h2>15. Sfaturi pentru o navigare sigura si responsabila, bazata pe module cookie</h2>
            <p>
                Datorita flexibilitatii lor si a faptului ca majoritatea dintre cele mai vizitate website-uri si cele mai mari folosesc module cookie, acestea sunt aproape 
                inevitabile. Dezactivarea modulelor cookie nu va permite accesul utilizatorului pe website-urile cele mai raspandite si utilizate printre care Youtube, 
                Gmail, Yahoo si altele. Iata cateva sfaturi care va pot asigura ca navigati fara griji insa cu ajutorul modulelor cookie:
            </p>
            <p>
                ✓	Particularizati-va setarile browserului in ceea ce priveste modulele cookie pentru a reflecta un nivel confortabil pentru voi al securitatii utilizarii modulelor cookie.
            </p>
            <p>
                ✓	Daca nu va deranjeaza modulele cookie si sunteti singura persoana care utilizeaza dispozitivul, puteti seta termene lungi de expirare pentru stocarea istoricului de navigare si al datelor personale de acces.
            </p>
            <p>
                ✓	Daca impartiti accesul la dispozitiv, puteti lua in considerare setarea browserului pentru a sterge datele individuale de navigare de fiecare data cand inchideti browserul. Aceasta este o varianta de a accesa 
                website-urile care plaseaza modulele cookie si de a sterge orice informatie de vizitare la inchiderea sesiunii navigare.
            </p>
            <p>
                ✓	Instalati-va si updatati-va constant aplicatii anti-spyware.
            </p>
            <p>
                Multe dintre aplicatiile de detectare si prevenire a spyware-ului includ detectarea atacurilor pe website-uri. Astfel, impiedica browserul de la a accesa website-uri care ar putea sa exploateze vulnerabilitatile 
                browserului sau sa descarce software periculos.
            </p>
            <p>
                Asigurati-va ca aveti browserul mereu actualizat la ultimele versiuni disponibile. Multe dintre atacurile bazate pe module cookie se realizeaza exploatand 
                punctele slabe ale versiunilor vechi ale browserelor.
            </p>
            <p>
                Modulele cookie sunt pretutindeni si nu pot fi evitate daca doriti sa va bucurati de acces pe cele mai bune si cele mai mari website-uri de pe Internet – locale sau internationale. Cu o 
                intelegere clara a modului lor de operare si a beneficiilor pe care le aduc, puteti lua masurile necesare de securitate astel incat sa puteti naviga cu incredere pe internet.
            </p>
            <p>
                Dezactivarea si refuzul de a primi module cookie pot face anumite website-uri impracticabile sau dificil de vizitat si folosit. De asemenea, refuzul 
                de a accepta module cookie nu inseamna ca nu veti mai primi/vedea publicitate online. Este posibila setarea din browser pentru ca aceste module cookie sa nu mai 
                fie acceptate sau poti seta browserul sa accepte module cookie de la un website anume. Dar, de exemplu, daca nu esti inregistat folosind module cookie, 
                nu vei putea lasa comentarii.
            </p>
          </div>
        </div>
      </div>
    )
  }