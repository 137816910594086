export default function RedirectTax() {
    return (
      <div className="relative overflow-hidden bg-white py-16">        
        <div className="relative px-4 sm:px-6 lg:px-8">
         <div className="mx-auto max-w-7xl space-y-8 px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-prose text-base lg:max-w-none">
            <h2 className="text-lg font-semibold text-red-600">Regulament</h2>
            <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Redirecționează impozitul pe profit
            </p>
          </div>
          </div>
          <div className="prose prose-lg max-w-7xl py-16 px-4 sm:px-6 lg:px-8 prose-indigo mx-auto mt-6 text-gray-500">
            <h2>1. Introducere</h2>
            <p>
                Această politică se referă la cookie-uri și pagini web operate de IT Perspectives S.R.L cu sediul social în
                Sibiu, Str. general Dragalina, nr. 1, jud. Sibiu.                   
            </p>
            <p>
                La IT Perspectives S.R.L credem că suntem transparenți și deschiși cu privire la modul în care colectăm și 
                utilizăm datele dumneavoastră cu caracter personal. În spiritul transparenței, această politică oferă informații 
                detaliate despre cum și când folosim cookie-urile. Această politică privind cookie-urile se aplică oricărui serviciu
                 disponibil pe Site, pentru a permite clientului să vizualizeze informațiile de pe site folosind exclusiv 
                 mijloace electronice, inclusiv alte mijloace de comunicare la distanță (inclusiv telefon) care este legată 
                 de această politică sau care o încorporează de referință.            
            </p>
            <p>
                IT Perspectives S.R.L se obligă să vă protejeze cu privire la colectarea online de pe site-ul său 
                www.itperspectives.ro a oricăror date despre dumneavoastră. Această secțiune vă va spune cum folosim cookie-urile, 
                de ce și cum ne permite acest lucru să ne îmbunătățim serviciile. De asemenea, vă va spune cum să gestionați 
                cookie-urile care sunt stocate pe dispozitivul dvs.            
            </p>

          </div>
        </div>
      </div>
    )
  }