import { Footer } from "../components/Footer";
// import BankAccount from '../shared/utils/icons/icons8-bank-account-96.png';
import DonateIcon from "../shared/utils/icons/icons8-donate-96.png";
import Card from "../shared/utils/icons/icons8-magnetic-card-96.png";
import Percentage from '../shared/utils/icons/icons8-percentage-96.png';
import FormularVenitDownload from '../shared/utils/documents/D230-DONATII_PERSONE_FIZICE.pdf';
// import Iframe from 'react-iframe';


export default function Donate() {

  const resizeIframe = (o) => {
  };

  const currentYear = new Date().getFullYear();
  const previousYear = new Date().getFullYear() - 1;

  return (
    <>
      <div className="overflow-hidden bg-gray-50 pt-40 pb-16">
        <div className="mx-auto max-w-prose lg:max-w-7xl space-y-8 px-4 md:px-0 sm:px-6 lg:px-8">
          <div className="mx-auto text-base max-w-none">
            <h2 className="text-lg font-semibold text-pink">Ajută-ne</h2>
            <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Donează
            </p>
          </div>

          {/* <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow lg:grid lg:grid-cols-2 lg:gap-px lg:divide-y-0"> */}
          <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow parent-donate-group">
            
          <div className="donate-online rounded-tr-lg relative group bg-white p-6 focus-within:ring-1 focus-within:ring-inset focus-within:ring-blueDDB-500">
              <div>
                <span className="rounded-lg inline-flex p-2 ring- bg-blueDDB-100 w-12">
                  <img src={Card} alt="Donează Online" />
                </span>
              </div>
              <div className="mt-8">
                <h2 className="text-lg font-medium">
                  <a href="" className="focus:outline-none">
                    {/* <span className="absolute inset-0" aria-hidden="true" /> */}
                    Donează Online
                  </a>
                </h2>
                  <iframe 
                    id="gp-donation-form"                     
                    src="https://pay.galantom.ro/widgets/donation_forms/view?id=81"
                    onLoad={(e) => resizeIframe(e)}
                    sandbox="allow-top-navigation allow-scripts allow-popups allow-forms allow-same-origin allow-modals" 
                    style={{
                      width: "100%",
                      border:0,
                      height: "1600px",
                      opacity: 1,
                      visibility: "visible"                      
                     }}>
                  </iframe>
              </div>
              <span
                className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-pink"
                aria-hidden="true"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </div>

            <div className="donate-bank-account border-none rounded-tl-lg relative group bg-white p-6 focus-within:ring-1 focus-within:ring-inset focus-within:ring-blueDDB-500">
              <div>
                <span className="rounded-lg inline-flex p-2 ring- bg-blueDDB-100 w-12">
                  <img src={DonateIcon} alt="Cont Bancar" />
                </span>
              </div>
              <div className="mt-8">
                <h2 className="text-lg font-medium">
                  Donează prin cont bancar
                </h2>
                <p className="mt-2 text-base leading-7 text-gray-500">
                  Dacă și ție ți-e drag de bine și vrei să ne susții, poți dona
                  direct în contul nostru bancar:
                </p>
                <br />
                <p className="text-base leading-7 text-gray-500">
                <span className="font-bold">IBAN RON</span> -
                  RO25BTRLRONCRT0657261701                  
                  <br />                  
                  <span>sau</span>
                  <br />                  
                  <span className="font-bold">IBAN EUR</span> -
                  RO72BTRLEURCRT0657261701
                  <br />
                  <br />
                  <span className="font-bold">Swift</span> - BTRLRO22
                  <br />
                  <span className="font-bold">Beneficiar:</span> - Asociația
                  Drag de bine
                  <br />
                  <span className="font-bold">Adresă:</span> - Str. General Ion
                  Dragalina, Nr. 1, Sibiu
                  <br />
                </p>
                <p className="mt-2 text-base leading-7 text-gray-500">
                  <br />
                  Ne puteți verifica prezența în Registrul entităților și
                  unităților de cult după Codul de Înregistrare Fiscală
                  46515374, aici:&nbsp;
                </p>
                <a
                  className="text-base leading-7 text-gray-500 break-all"
                  href="https://www.anaf.ro/RegistrulEntitatilorUnitatilorCult/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="underline">
                    https://www.anaf.ro/RegistrulEntitatilorUnitatilorCult/
                  </span>
                </a>
              </div>
              <span
                className="absolute top-6 right-6 text-gray-300 group-hover:text-pink"
                aria-hidden="true"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </div>

            
            {/* <div className='rounded-bl-lg relative group bg-white hover:bg-gray-50 p-6 focus-within:ring-1 focus-within:ring-inset focus-within:ring-red-500'>
                <div>
                  <span className='rounded-lg inline-flex p-2 ring- bg-sky-50 w-12'>
                    <img src={Percentage} alt="Cont Bancar" />
                  </span>
                </div>
                <div className="mt-8">
                  <h2 className="text-lg font-medium">
                    <a href="../Legal/RedirectIncomeTax" className="focus:outline-none">
                      <span className="absolute inset-0" aria-hidden="true" />
                      Redirecționează 3.5% din impozitul pe venit
                    </a>
                  </h2>
                  <p className="mt-2 text-base leading-7 text-gray-500">
                    Donează
                  </p>
                </div>
                <span
                  className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-red-400"
                  aria-hidden="true"
                >
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                  </svg>
                </span>                
            </div> */}
            <div className='donate-redirect-profit border-none rounded-br-lg relative group bg-white p-6 focus-within:ring-1 focus-within:ring-inset'>
                <div>
                  <span className='rounded-lg inline-flex p-2 bg-blueDDB-100 w-12'>
                    <img src={Percentage} alt="Cont Bancar" />
                  </span>
                </div>
                <div className="mt-8">
                  <h2 className="text-lg font-medium">                  
                      Redirecționează 3,5% din impozitul pe venit                    
                  </h2>
                  <p className="mt-2 text-base leading-7 text-gray-500">
                  Să faci bine poate fi gratuit! Redirecționând până la 3,5% din impozitul anual, poți să ne susții financiar în activitățile cu copiii, fără să simți vreo diferență în portofel (sau în contul bancar). Dacă în {previousYear} ai fost angajat sau pensionar, poți dona 3,5% din impozitul pe venit – pe care oricum l-ai plătit deja către statul român. 
                  </p>
                  <p className="mt-6 text-base leading-7 text-gray-500">
                  <span className="font-bold">Cum poți redirecționa 3.5% din impozit?</span>  
                  <br /><br />
                    <span className="font-bold">Descarcă&nbsp;</span> 
                    <a 
                      className="underline text-blueDDB-600"
                      href={FormularVenitDownload}
                      download
                      >formularul</a>
                     &nbsp; și urmează acești pași: imprimă-l, completează-l cu datele tale la secțiunea I, semnează-l la semnătura contribuabilului, scanează-l și trimite-l spre noi pe adresa <span className="font-bold">contact@dragdebine.ro</span>.
                    <br /><br />
                    <span className="font-bold">SAU</span>
                    <br /><br />
                    <span className="font-bold">Completează online</span> <a 
                      className="underline text-blueDDB-600"
                      href="https://redirectioneaza.ro/dragdebine"
                      target="_blank"                      
                      >formularul.</a>
                    <br /><br />
                    Noi și toți copiii pe care îi sprijinim să își atingă potențialul îți mulțumim! 😊 Fondurile strânse le vom folosi pentru a le oferi copiilor vulnerabili din medii rurale instrumentele necesare ca, în timp, să se dezvolte din punct de vedere educațional, social și emoțional.  
                  </p>
                  <p className="mt-6 text-base leading-7 text-gray-500">
                  Termenul limită pentru completare este 25 mai {currentYear}. Dacă întâmpini dificultăți în completarea formularului, scrie-ne la secțiunea Contact și te ajutăm cu drag. 
                  </p>
                </div>
                <span
                  className="absolute top-6 right-6 text-gray-300 group-hover:text-pink"
                  aria-hidden="true"
                >
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                  </svg>
                </span>                
            </div>
          </div>
        </div>
      </div>

      <section className="Footer">
        <Footer />
      </section>
    </>
  );
}
