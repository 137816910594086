import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { Footer } from "../components/Footer";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ContactForm } from "../components/ContactForms";

export default function Contact() {
  return (
    <>
      <div className="relative bg-white pt-24">
        <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
          <div className="bg-gray-50 pt-24 pb-16 px-4 sm:px-0 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="mx-auto max-w-prose lg:max-w-lg">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                Contactează-ne
              </h2>
              <dl className="mt-8 text-base text-gray-500">
                <div>
                  <dd>
                    <p className="font-bold">Asociația Drag de bine</p>
                  </dd>
                </div>
                <br />
                <div>
                  <dd>
                    <p>Str. General Ion Dragalina, Nr. 1</p>
                    <p>Sibiu, Romania</p>
                  </dd>
                </div>
                <br />
                <div>
                  <dd>
                    <p>CIF: 46515374</p>
                  </dd>
                </div>
                <div className="mt-6">
                  <dt className="sr-only">Numar de telefon</dt>
                  <dd className="flex">
                    <PhoneIcon
                      className="h-6 w-6 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">+40 739 925 643</span>
                  </dd>
                </div>
                <div className="mt-3">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <EnvelopeIcon
                      className="h-6 w-6 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">contact@dragdebine.ro</span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
            <div className="mx-auto max-w-prose lg:max-w-lg">
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
              >
                <ContactForm />
              </GoogleReCaptchaProvider>
            </div>
          </div>
        </div>
      </div>

      <section className="Footer">
        <Footer />
      </section>
    </>
  );
}
