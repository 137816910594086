export default function TermsAndConditions() {
    return (
      <div className="relative overflow-hidden bg-white pt-40 pb-16">        
        <div className="relative px-4 sm:px-6 lg:px-8">
         <div className="mx-auto max-w-7xl space-y-8 px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-prose text-base lg:max-w-none">
            <h2 className="text-lg font-semibold text-pink">Regulament</h2>
            <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Termeni și condiții
            </p>
          </div>
          </div>
          <div className="prose prose-lg max-w-7xl py-16 px-4 sm:px-6 lg:px-8 prose-indigo mx-auto mt-6 text-gray-500">
            <p>
              Utilizarea serviciilor oferite de ASOCIAȚIA DRAG DE BINE se aplica fiecarui utilizator in parte si presupune acceptarea in totalitate a acestora si acordul 
              dumneavoastra vizavi de termenii si conditiile de mai jos.
            </p>
            <p>
              Accesul si utilizarea site-ului www.dragdebine.ro sunt gratuite si au scopul de a veni in ajutorul utilizatorilor pentru a gasi informatiile necesare in cel 
              mai usor si rapid mod posibil, conform cerintelor fiecaruia.
            </p>
            <p>
            Site-ul www.dragdebine.ro este proprietatea ASOCIAȚIA DRAG DE BINE cu sediul in Sibiu, Str. General Dragalina, nr. 1, județul Sibiu, înregistrată în Registrul National ONG cu nr. 48/I/A/2022, cod de înregistrare fiscală 46515374, este un site dedicat persoanelor juridice/fizice si oferă sprijin pentru persoane și comunități defavorizate, prin acțiuni de suport care promovează și susțin educația și sănătatea.
            </p>


            <h2>1. Termeni generali</h2>
            <p>
              www.dragdebine.ro include: texte, imagini, baze de date, servicii, logo-uri, reclame si constituie “continutul” site-ului mentionat, care este proprietatea 
              ASOCIAȚIA DRAG DE BINE.
            </p>
            <p>
              ASOCIAȚIA DRAG DE BINE isi rezerva dreptul, unilateral, de a schimba, corecta, modifica, adauga si/sau retrage prezentii termeni, fara a anunta in prealabil. 
              Aceste schimbari vor intra in vigoare din momentul publicarii lor pe www.dragdebine.ro. Totodata, ASOCIAȚIA DRAG DE BINE poate limita accesul la site 
              sau la anumite facilitati din site, poate aduce limitari anumitor IP-uri, fara notificari si fara vreo raspundere.
            </p>


            <h2>2. Utilizarea continutului site-ului</h2>
            <p>
              Informatiile prezente pe www.dragdebine.ro sunt de interes general si sunt puse la dispozitia utilizatorilor in mod gratuit. Prin termenul “utilizator” al 
              acestui site se intelege orice persoana fizica sau juridica care va accesa acest site.  
            </p>
            <p>
              Puteti copia si tipari continutul site-ului www.dragdebine.ro pentru folosinta dumneavoastra personala, fara intentii comerciale. In orice alte situatii, 
              continutul www.dragdebine.ro nu poate fi reprodus, modificat sau exploatat.  
            </p>
            <p>
              Utilizarea site-ului www.dragdebine.ro presupune acceptarea acestor termeni si conditii de utilizare.
            </p>
            <p>
              Nu sunt permise <br />
               <ul>
                <li>modificarea, publicarea, transmiterea, transferul, vanzarea, distributia de materiale realizate prin reproducerea, afisarea sau modificarea continutului www.dragdebine.ro fara obtinerea acordului prealabil a ASOCIAȚIA DRAG DE BINE</li>
                <li>indepartarea insemnului care marcheaza dreptul de autor al www.dragdebine.ro asupra continutului acestuia</li>
                <li>reproducerea continutului www.dragdebine.ro si trimiterea acestuia catre alte website-uri sau servere sau alte mijloace de stocare a informatiei.</li>
                <li>preluarea, prelucrarea, reproducerea si folosirea informatiilor obtinute prin intermediul www.dragdebine.ro , in scopuri comerciale.</li>
              </ul>
            </p>
            <p>
              Este interzisa orice utilizare a continutului www.dragdebine.ro in alte scopuri decat cele permise expres de prezentul document sau de legislatia in vigoare. Cererile de utilizare a continutului in alte scopuri decat cele permise expres de prezentul document pot fi trimise la adresa de email eliza@dragdebine.ro .
            </p>
            

            <h2>3. Dreptul de autor</h2>
            <p>
              Continutul www.dragdebine.ro este proprietatea ASOCIAȚIA DRAG DE BINE si este aparat de legea pentru protectia drepturilor de autor, toate drepturile fiind rezervate. Folosirea oricaror date, imagini, texte, grafici, simboluri, elemente de grafica web, scripturi, programe apartinand www.dragdebine.ro  fara obtinerea unui acord prealabil de la detinatorii de drept, se pedepseste conform legilor in vigoare.   
            </p>
            <p>
              Continutul www.dragdebine.ro nu poate fi reprodus, modificat, transferat, distribuit, republicat, copiat sau transmis fara permisiunea anterioara scrisa a reprezentantilor ASOCIAȚIA DRAG DE BINE.  
            </p>


            <h2>4. Informatiile oferite prin intermediul site-ului si protectia datelor cu caracter personal</h2>
            <p>
                Completarea formularului de contact este considerat consimtamantul expres si neechivoc al utilizatorului pentru folosirea datelor sale personale in scopul contactarii si transmiterii de mesaje electronice pe baza subiectului comunicat de utilizator. ASOCIAȚIA DRAG DE BINE nu isi asuma raspunderea pentru corectitudinea datelor furnizate de catre utilizatori prin intermediul formularului de contact. Conform cerintelor Regulamentului European 679/2016 pentru protectia persoanelor cu privire la prelucrarea datelor cu caracter personal si libera circulatie a acestor date, ASOCIAȚIA DRAG DE BINE are obligatia de a administra in conditii de siguranta, si numai pentru scopurile specificate, datele personale furnizate de catre utilizatori. De asemenea, va aducem la cunostinta faptul ca utilizatorul poate manifesta oricand dreptul de opozitie, caz in care vom elimina toate datele personale ale acestuia din baza noastra de date. Pentru exercitarea drepturilor dumneavoastra referitoare la utilizarea www.dragdebine.ro si la protectia utilizarii, ne puteti contacta prin intermediul sectiunii “Contact” din site.      </p>
            <p>
              Scopurile in care ASOCIAȚIA DRAG DE BINE poate utiliza aceste informatii si persoanele carora le pot fi oferite sunt asociate comercializarii serviciilor ASOCIAȚIA DRAG DE BINE, cum ar fi:
              <br />
               <ul>
                <li>solutionarea cererilor, intrebarilor sau a reclamatiilor facute de catre utilizatorii site-ului;</li>
                <li>realizarea de studii de piata produselor si serviciilor ASOCIAȚIA DRAG DE BINE, contactarea utilizatorilor (pe e-mail sau telefonic) in legatura cu alte oferte de servicii apartinand ASOCIAȚIA DRAG DE BINE sau in legatura cu servicii ale unor terte parti atent selectionate, care credem ca ar putea fi de interes pentru utilizatori (in afara de cazul in care ati facut o cerere scrisa de a nu fi contactat in acest sens);</li>
                <li>transmiterea de mesaje de felicitare cu ocazia sarbatorilor legale.</li>
                <li>inregistrarea informatiilor dumneavoastra in vederea alocarii sau oferirii recompenselor, reducerilor si a altor beneficii, precum si pentru satisfacerea altor cereri sau cerinte pe care le aveti in legatura cu programele de recompensare a clientilor sau alte programe similare.</li>
              </ul>
            </p>

            <p className="Drepturile utilizatorilor:"></p>
            <p>
              a. sa rectifice, actualizeze, blocheze sau stearga, in mod gratuit, acele date furnizate a caror prelucrare nu este conforma dispozitiilor Regulamentului European 679/2016;
            </p>
            <p>
              b. sa solicite incetarea prelucrarii datelor sale personale.
            </p>
            <p>
              Orice incercare de a accesa datele personale ale altui utilizator, de a modifica continutul www.dragdebine.ro sau de a afecta performantele serverului pe care ruleaza acesta, va fi considerata o tentativa de fraudare a site-ului si va conduce la cercetarea penala impotriva aceluia sau acelora care a(u) incercat acest fapt.
            </p>
            <p>
              ASOCIAȚIA DRAG DE BINE nu ofera informatia colectata unor terti fara consimtamantul dumneavoastra expres si prealabil. Orice statistica privind traficul utilizatorilor site-ului, pe care o vom oferi catre terte persoane, este furnizata doar ca ansamblu de date si nu include nicio informatie identificabila personal despre niciun utilizator individual.
            </p>


            <h2>5. Limitarea raspunderii</h2>
            <p>
              ASOCIAȚIA DRAG DE BINE nu este raspunzator pentru niciun fel de pagube, directe sau indirecte, rezultate din:<br />
               <ul>
                <li>utilizarea sau imposibilitatea utilizarii informatiilor www.dragdebine.ro;</li>
                <li>declaratii/actiuni ale unei terte parti asupra serviciilor www.dragdebine.ro</li>
                <li>orice alte probleme legate de serviciile oferite de www.dragdebine.ro</li>
              </ul>
            </p>

            <h2>6. Drept aplicabil</h2>
            <p>
              Termenii si Conditiile prezente, precum si orice litigiu in legatura cu sau decurgand din clauzele acestora sau din accesarea / functionarea / disfunctionalitatea site-ului se supun dreptului intern roman. Limba folosita pentru Termeni si Conditii este limba romana. In caz de litigiu, numai instantele romanesti vor fi considerate competente. 
            </p>
            <p>
              Utilizarea www.dragdebine.ro de catre client implica acceptarea neconditionata a acestor Termeni si Conditii.
            </p>

            <h2>7. Schimbari ale site-ului</h2>
            <p>
              ASOCIAȚIA DRAG DE BINE isi rezerva dreptul de a suspenda, modifica, adauga sau sterge in orice moment portiuni ale continutului site-ului www.dragdebine.ro. De asemenea, ASOCIAȚIA DRAG DE BINE isi rezerva dreptul de a restrictiona accesul utilizatorilor la o parte sau la intregul continut al www.dragdebine.ro.            </p>
            <p>
              Anumite parti ale continutului publicat pe www.dragdebine.ro pot sa fie furnizate catre terte persoane cu care ASOCIAȚIA DRAG DE BINE are relatii contractuale in acest sens. De asemenea, in interiorul continutului www.dragdebine.ro pot fi incluse sectiuni publicitare in care se vor afisa mesaje publicitare ale unor terte persoane. In acest sens, www.dragdebine.ro nu este responsabil in nicio masura asupra continutului furnizat de terte persoane, indiferent ca acesta este publicitar sau nu. De asemenea, ASOCIAȚIA DRAG DE BINE nu este responsabil de continutul paginilor externe la care se face trimitere din interiorul site-ului.
            </p>

            <h2>8. Modificarea prezentei sectiuni de termeni si conditii</h2>
            <p>
              ASOCIAȚIA DRAG DE BINE isi rezerva dreptul sa schimbe acesti termeni, modificand versiunea si data adoptarii noului regulament fara indeplinirea unor alte formalitati. Atunci cand acesti termeni se vor modifica, ASOCIAȚIA DRAG DE BINE. va va putea notifica prin intermediul emailului si prin publicarea in prima pagina a siteului, a unui link care face trimitere la noua forma a prezentului document.
            </p>

            <h2>9. Confidentialitate</h2>
            <p>
              Orice date cu caracter personal precum: numele, adresa, numarul de telefon sau adresa de e-mail a dumneavoastra, pe care o transmiteti pe site-ul www.dragdebine.ro prin email, vor fi folosite in conformitate cu Politica de Confidentialitate.. Orice alt fel de comunicari sau materiale pe care le transmiteti pe acest site, precum intrebari, comentarii, sugestii sau alte mesaje de acest fel vor fi considerate ca neconfidentiale si neprotejate de drepturi de proprietate intelectuala determinate.
            </p>

            <h2>10. Protectia datelor cu caracter personal</h2>
            <p>
              Referitor la angajamentul ASOCIAȚIA DRAG DE BINE cu privire la protectia datelor cu caracter personal, increderea dumneavoastra in serviciile si personalul nostru este una dintre principalele noastre preocupari. Pentru a va furniza cea mai buna experienta posibila, ne concentram pe continua imbunatatire in intreaga noastra activitate.
            </p>
            <p>
              ASOCIAȚIA DRAG DE BINE cunoaste importanta datelor dumneavoastra personale si se angajeaza sa protejeze confidentialitatea si securitatea acestora. De aceea, este important pentru noi sa va furnizam intr-o maniera integrata informatiile legate de prelucrarea datelor dumneavoastra cu caracter personal, in cadrul prezentelor informatii din Termeni si Conditii.
            </p>
            <p>
              ASOCIAȚIA DRAG DE BINE respecta confidentialitatea tuturor clientilor nostri si confidentialitatea site-ului nostru si va trata/prelucra datele personale cu mare atentie, in conditii tehnice si organizatorice de securitate adecvata.
            </p>
            <p>
              ASOCIAȚIA DRAG DE BINE prelucreaza datele cu caracter personal primite direct de la dumneavoastra, in calitate de persoane vizate, colectate prin intermediul formularului de contact.
            </p>
            <p>
              Datele cu caracter personal sunt colectate fie numai cu acordul/acceptul dumneavoastra, daca sunt oferite cu buna stiinta, de buna voie, din proprie initiativa, fie la solicitarea ASOCIAȚIA DRAG DE BINE, daca acestea sunt necesare pentru a configurarea unui raspuns la salocitarea utilizatorului.
            </p>
            <p>
              Utilizarea acestui website www.dragdebine.ro de catre dumneavoastra se supune prezentelor prevederi din prezentul document ce apartine ASOCIAȚIA DRAG DE BINE.
            </p>
            <p>
              Daca nu sunteti de acord cu oricare dintre termenii prezentelor Termeni si Conditii, nu utilizati website-ul nostru www.dragdebine.ro sau nu ne furnizati datele dvs. personale. Este posibil ca anumite servicii sa fie conditionate de furnizarea datelor dvs. personale.
            </p>
            <p>
              ASOCIAȚIA DRAG DE BINE nu va prelucra datele dumneavoastra personale decat in masura in care acest demers este necesar indeplinirii scopurilor mentionate, cu respectarea masurilor legale de securitate si confidentialitate a datelor.
            </p>
            <p>
              <span className="font-bold">Ce date cu caracter personal prelucreaza ASOCIAȚIA DRAG DE BINE?</span>
              <br />
              Prelucrarea datelor cu caracter personal reprezinta orice operatiune sau set de operatiuni, care se efectueaza asupra datelor dumneavoastra cu caracter personal, prin mijloace automate sau neautomate, cum ar fi: colectarea, inregistrarea, organizarea, structurarea, stocarea, adaptarea sau modificarea, extragerea, consultarea, utilizarea, divulgarea prin transmitere, diseminarea, sau punerea la dispozitie in orice alt mod, alinierea sau combinarea, restrictionarea, blocarea, stergerea, distrugerea, arhivarea.
            </p>
            <p>
              Pentru a accesa website-ul www.dragdebine.ro, nu este nevoie sa ne furnizati niciun fel de date cu caracter personal.
            </p>
            <p>
              <span className="font-bold">Consecintele refuzului furnizarii datelor cu caracter personal</span>
              <br />
              Dumneavoastra, in calitate de persoana vizata, sunteti obligati sa furnizati date complete, actualizate si corecte.
            </p>
            <p>
              Pentru a va putea oferi detalii specifice activitatii ASOCIAȚIA DRAG DE BINE, www.dragdebine.ro va solicita datele cu caracter personal necesare acelor activitati, iar refuzul de a furniza astfel de date poate determina imposibilitatea furnizarii serviciilor ASOCIAȚIA DRAG DE BINE.
            </p>
            <p>
              Prin citirea prezentelor prevederi, prin furnizarea datelor personale in vederea accesarii serviciilor ASOCIAȚIA DRAG DE BINE, dumneavoastra sunteti informat/a si/sau va exprimati in mod expres consimtamantul (va dati acordul) cu privire la prelucrarea datelor personale in conformitate cu dispozitiile legale privind protectia persoanelor fizice in ceea ce priveste prelucrarea datelor cu caracter personal si libera circulatie a acestor date (legislatia interna si dreptul comunitar-UE).
            </p>
            <p>
              In cazul in care prelucrarea datelor nu mai este necesara pentru oferirea unui serviciu, pentru indeplinirea unei obligatii legale, si nici nu este justificata de un interes legitim al ASOCIAȚIA DRAG DE BINE, dumneavoastra puteti refuza o astfel de prelucrare sau va puteti retrage consimtamantul dat anterior, refuzul urmand sa produca efecte pentru viitor.
            </p>
            <p>
              <span className="font-bold">Care sunt scopurile colectarii datelor cu caracter personal?</span>
              <br />
              Utilizam datele dumneavoastra cu caracter personal doar in urmatoarele scopuri:
              <br />
               <ul>
                <li>Gestionarea relatiilor cu clientii;</li>
                <li>Comunicarea comerciala cu clientii prin orice mijloc de comunicare;</li>
                <li>Indeplinirea obligatiilor legale/incidente;</li>
                <li>Comunicarea cu organismele/autoritatile/institutiile publice sau de interes public;</li>
                <li>Arhivare, scopuri statistice</li>
              </ul>
            </p>
            <p>
              ASOCIAȚIA DRAG DE BINE va considera toate informatiile colectate de la dumneavoastra ca fiind confidentiale si nu le va partaja cu terti fara consimtamantul dumneavoastra expres si anterior.
            </p>
            <p>
              <span className="font-bold">Cine sunt destinatarii datelor dumneavoastra cu caracter personal?</span>
              <br />
              Destinatarii datelor pot fi chiar persoanele (vizate) care au furnizat datele personale, dupa cum urmeaza:
              <br />
               <ul>
                <li>Clienti persoane fizice ai ASOCIAȚIA DRAG DE BINE (actuali, fosti sau potentiali), vizitatori, public larg, reprezentanti/imputerniciti acestora, legali sau conventionali;</li>
                <li>Clienti persoane organizatori, co-organizatori, furnizori, prestatori si alte entitati juridice-parteneri de afaceri/contractuali ai ASOCIAȚIA DRAG DE BINE, autoritati/institutii publice;</li>
              </ul>
            </p>
            <p>
              Destinatarii (altii decat persoanele vizate) datelor pot fi autoritati publice centrale si locale, autoritati judecatoresti, politie, parchet (in limitele prevederilor legale si/sau ca urmare a unor cereri expres formulate), societati bancare, executori judecatoresti, societati de asigurare si reasigurare, organizatii de cercetare a pietei (scop statistic) etc.
            </p>
            <p>
              Confidentialitatea datelor cu caracter personal va fi asigurata de catre ASOCIAȚIA DRAG DE BINE si nu vor fi furnizate catre alti terti in afara celor mentionati in prezentul document.
            </p>
            <p>
              <span className="font-bold">Prelucrarea datelor cu caracter personal in scop promotional (marketing) </span>
              <br />
              Datele cu caracter personal furnizate de dumneavoastra (cum ar fi: nume si prenume, adresa de email, nr. telefon mobil/fix etc.) vor putea fi prelucrate de ASOCIAȚIA DRAG DE BINE cu respectarea drepturilor dvs., in special a dreptului de informare si opozitie, in urmatoarele scopuri: marketing (inclusiv marketing direct), publicitare, efectuarea de comunicari comerciale pentru serviciile ASOCIAȚIA DRAG DE BINE, inclusiv cele dezvoltate impreuna cu un partener ASOCIAȚIA DRAG DE BINE, prin orice mijloc de comunicare, inclusiv prin intermediul serviciilor de comunicatii electronice. Datele cu caracter personal furnizate de dumneavoastra vor putea fi folosite in scop promotional (marketing) si pentru serviciile altor parteneri ai ASOCIAȚIA DRAG DE BINE, cu respectarea drepturilor dumneavoastra.
            </p>
            <p>
              In cazul in care ASOCIAȚIA DRAG DE BINE intentioneaza sa utilizeze datele dumneavoastra in scopuri de marketing direct, (daca persoanele vizate au optat in acest sens) dvs. veti fi informati in prealabil.
            </p>
            <p>
              Indiferent de situatie, in cazul in care veti dori ca datele cu caracter personal sa inceteze a mai fi prelucrate de ASOCIAȚIA DRAG DE BINE, ne puteti solicita in mod expres incetarea oricarei prelucrari de date din partea noastra.
            </p>
            <p>
              <span className="font-bold">Durata prelucrarii datelor cu caracter personal </span>
              <br />
              In vederea realizarii scopului mentionat, ASOCIAȚIA DRAG DE BINE va prelucra datele dumneavoastra cu caracter personal pe toata perioada de desfasurare a activitatilor ASOCIAȚIA DRAG DE BINE, pana in momentul in care dumneavoastra, sau reprezentantul legal/conventional al dvs, veti exercita dreptul de opozitie/ de stergere (cu exceptia situatiei in care ASOCIAȚIA DRAG DE BINE prelucreaza datele in baza unei obligatii legale sau justifica un interes legitim). Ulterior incheierii operatiunilor de prelucrare a datelor cu caracter personal, in scopul pentru care au fost colectate, daca dumneavoastra sau reprezentantul legal/conventional nu veti exercita dreptul de opozitie/de stergere, conform legii, aceste date vor fi arhivate de catre ASOCIAȚIA DRAG DE BINE pe durata de timp prevazuta in procedurile interne ASOCIAȚIA DRAG DE BINE si/sau vor fi distruse.
            </p>
            <p>
              <span className="font-bold">Ce masuri de siguranta sunt utilizate pentru protejarea datelor dumneavoastra?</span>
              Pentru a evita utilizarea fara drept a datelor personale, precum si eventualele abuzuri, utilizam metode si tehnologii de securitate, impreuna cu politici si proceduri de lucru adecvate, pentru a proteja datele cu caracter personal colectate.
            </p>
            <p>
              Securitatea integrala a datelor transmise prin internet nu poate fi niciodata garantata. In timp ce ASOCIAȚIA DRAG DE BINE depune toate eforturile in privinta protejarii datelor dumneavoastra personale, nu putem garanta in mod absolut securitatea datelor. Orice transmisie de date se face pe riscul dumneavoastra. Odata ce primim informatia referitoare la dumneavoastra, vom utiliza proceduri si masuri stricte de siguranta impotriva utilizarii neautorizate, nelegale, distrugerii, pierderii accidentale sau divulgarii datelor personale.
            </p>
            <p>
              <span className="font-bold">Care sunt drepturile dumneavoastra?</span>
              Prin citirea acestui document, dumneavoastra ati fost informat/a cu privire la drepturile de care beneficiati, conform prevederilor legale aplicabile, respectiv: dreptul de acces, dreptul la rectificare, dreptul la stergerea datelor, dreptul la restrictionarea prelucrarii, dreptul la portabilitatea datelor, dreptul la opozitie si procesul individual automatizat. Totodata, atunci cand prelucrarea se realizeaza pe consimtamant, dumneavoastra, aveti dreptul de a va retrage consimtamantul in orice moment in forma scrisa, adresata eliza@dragdebine.ro in conditiile legislatiei aplicabile, fara a afecta legalitatea prelucrarii efectuate pe baza consimtamantului inainte de retragerea acestuia.
            </p>
            <p>
              De asemenea, dumneavoastra aveti dreptul de a va opune in orice moment, in mod gratuit si fara nicio justificare, prelucrarii datelor personale in scop de marketing direct, utilizand, daca este cazul, functia de dezabonare inclusa in materialele de marketing.
            </p>
            <p>
              Pentru celelalte scopuri, dumneavoastra aveti dreptul de a va opune, in orice moment, in mod gratuit, ca datele cu caracter personal pe care le furnizati catre ASOCIAȚIA DRAG DE BINE sa faca obiectul unei prelucrari, cu mentionarea datelor si scopului avute in vedere, numai pentru motive intemeiate si legitime, legate de situatia dvs. particulara, cu exceptia cazurilor in care ASOCIAȚIA DRAG DE BINE demonstreaza ca are motive legitime si imperioase care justifica prelucrarea si care prevaleaza intereselor, drepturilor si libertatilor dumneavoastra sau ca scopul este constatarea, exercitarea sau apararea unui drept in instanta. In caz de opozitie nejustificata, ASOCIAȚIA DRAG DE BINE este indreptatit sa prelucreze in continuare datele in cauza.
            </p>
            <p>
              Prin citirea acestui document dumneavoastra ati luat la cunostinta si intelegeti faptul ca datele cu caracter personal furnizate catre ASOCIAȚIA DRAG DE BINE constituie elemente determinante pentru accesarea serviciilor oferite de ASOCIAȚIA DRAG DE BINE si/sau derularea activitatii comerciale/contractuale.
            </p>
            <p>
              <span className="font-bold">Cum va puteti exercita drepturile dumneavoastra?</span>
              Pentru exercitarea acestor drepturi, dumneavoastra va puteti adresa Specialistului de date personale din cadrul ASOCIAȚIA DRAG DE BINE cu o cerere scrisa, datata si semnata, la adresa de email: dpo@dragdebine.ro. Confirmarea identitatii solicitantului cererii se va efectua prin intermediul unei intalniri online.
              Aceste prevederi pot suferi modificari/actualizari ocazionale. Va recomandam sa verificati la fiecare utilizare a website-lui www.dragdebine.ro prezentele prevederi, pentru a fi tot timpul informati in legatura cu acestea. Prin accesarea website-lui www.dragdebine.ro sau utilizarea serviciilor ASOCIAȚIA DRAG DE BINE prin intermediul website-urilor mentionate, ulterior modificarilor, se vor considera acceptate noile prevederi.
            </p>
          </div>
        </div>
      </div>
    )
  }