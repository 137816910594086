import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Logo from "../shared/utils/images/logo_font_negru.webp";


const navigation = [
  { name: 'Acasă', href: '/home' },
  { name: 'Despre noi', href: '/about' },
  // { name: 'Supereroi la școală', href: '/superheroes' },
  // { name: 'Știri', href: '/news' },
  { name: 'Voluntariat', href: '/volunteer' },
  { name: 'Contact', href: '/contact' }
]

var prevScrollpos = window.pageYOffset;
var windowSize = window.innerWidth;

window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("navbar").style.top = "0";
  } 
  else if (windowSize >= 768) {    
    document.getElementById("navbar").style.top = "-88px";
  }
  else {
    document.getElementById("navbar").style.top = "0";
  }
  prevScrollpos = currentScrollPos;
}


export default function NavigationMenu() {
  return (
    <div id="navbar" className="w-full fixed bg-white z-50 top-0 duration-300">
      <div className="relative pt-6 pb-6 sm:pb-6">
        <Popover>
          <div className="mx-auto max-w-7xl px-4 sm:px-6">
            <nav className="relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
              <div className="flex flex-1 items-center md:absolute md:inset-y-0 md:left-0">
                <div className="flex w-full items-center justify-between md:w-auto">
                  <a href="/home">
                    <img
                      className="h-[4rem] w-auto"
                      src={Logo}
                      alt="Logo Drag de bine"                      
                    />
                  </a>
                  <div className="-mr-2 flex items-center md:hidden">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-gray-100 p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purpleDDB-500">
                      <span className="sr-only">Open main menu</span>
                      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="hidden md:flex md:space-x-10">
                {navigation.map((item) => (
                  <a key={item.name} href={item.href} className="font-medium text-gray-500 hover:text-gray-900">
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="hidden md:absolute md:inset-y-0 md:right-0 md:flex md:items-center md:justify-end">
                <a href="/Donate">
                  <button
                      type="button"
                      className="inline-flex items-center rounded-md border border-transparent bg-purpleDDB-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-purpleDDB-600 focus:outline-none focus:ring-2 focus:ring-purpleDDB-500 focus:ring-offset-2"
                    >
                    Donează
                  </button>
                </a>
              </div>
            </nav>
          </div>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
            >
              <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
                <div className="flex items-center justify-between px-5 pt-4">
                  <div>
                    <a href="/Home">
                      <img
                        className="h-8 w-auto"
                        src={Logo}
                        alt="Logo Drag de bine"
                      />
                    </a>                    
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purpleDDB-500">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                    >
                      {item.name}
                    </a>
                  ))}
                
                  <a href="/Donate">
                    <button
                        type="button"
                        className="inline-flex w-full items-center rounded-md border border-transparent bg-purpleDDB-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-purpleDDB-600 focus:outline-none focus:ring-2 focus:ring-purpleDDB-500 focus:ring-offset-2"
                      >
                      Donează
                    </button>
                  </a>              
                </div>                                
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>       
      </div>
    </div>
  )
}
